<template>
  <div>
    <privacyIt v-if="languageReactive === 'it'"></privacyIt>
    <privacyEn v-else></privacyEn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrivacyComponentIt from "./it/PrivacyComponent.vue";
import PrivacyComponentEn from "./en/PrivacyComponent.vue";

export default {
  name: "PrivacyView",
  components: {
    privacyIt: PrivacyComponentIt,
    privacyEn: PrivacyComponentEn,
  },
  computed: {
    ...mapGetters({ language: "language" }),
  },

  watch: {
    language(value) {
      this.languageReactive = value;
    },
  },
  data() {
    return {
      languageReactive: null,
    };
  },
  created() {
    this.languageReactive = this.language ?? localStorage.getItem("language");
  },
};
</script>
