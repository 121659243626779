<template>
  <v-row dense class="bg-page-primary">
    <v-col
      cols="12"
      xl="6"
      lg="6"
      md="6"
      sm="10"
      xs="10"
      class="mx-auto p-4"
      style="justify-content: space-evenly"
    >
      <v-card
        color="surface"
        id="privacy"
        class="px-2 overflow-visible"
        style="display: grid; text-align: justify"
      >
        <v-btn
          v-if="history"
          class="close-popup"
          icon="mdi-close"
          alt="Close"
          @click="this.$router.back()"
          size="x-small"
          color="button-primary"
        ></v-btn>

        <v-card-title class="h4 m-auto text-center text-wrap"
          >Privacy Policy – “Legenda” Service
        </v-card-title>
        <v-card-text>
          <div>
            <p class="p-1">
              In accordance with Article 13 of EU Regulation No. 2016/679,
              concerning the protection of individuals with regard to the
              processing of personal data and the free movement of such data,
              and repealing Directive 95/46/EC (hereinafter referred to as the
              "GDPR"), this document informs Users of the Service (the Data
              Subjects) that the personal data provided by them will be
              processed in compliance with the aforementioned regulations and
              about the confidentiality obligations under the agreements with
              Metis Srl.
            </p>
          </div>

          <div>
            <span class="h5">Data Controller</span>
            <p class="p-1">
              The Data Controller is Metis Srl, with registered office in Milan,
              Piazza Sicilia 8, contactable via email at
              <a href="mailto:info@metisai.it">info@metisai.it</a>.
            </p>
          </div>

          <div>
            <span class="h5">Data Processors</span>
            <p class="p-1">
              The Data Processor for the processing of User's electronic
              documents, in case they contain personal data, is Amazon Web
              Services EMEA SARL, with registered office in Luxembourg, 38
              Avenue John F Kennedy L-1855.
            </p>
            <p class="px-1">
              The Data Processor for payment management activities is Stripe
              Payments Europe, Limited, with registered office in Ireland, 3
              Dublin Landings, North Wall Quay, Dublin 1, Dublin.
            </p>
            <p class="px-1">
              Additional parties may be designated by the Data Controller as
              Data Processors, with specific assignments, for activities
              including maintenance, development, improvement, and management of
              the functionalities of the Data Controller's products and
              services.
            </p>
            <p class="px-1">
              The Data Subject may contact the Data Controller at any time to
              obtain the list and contact details of the Data Processors.
            </p>
          </div>

          <div>
            <span class="h5">Purposes of Processing and Legal Basis</span>
            <ol type="1" class="list-padding">
              <li class="ml-4 p-1">
                <p>
                  The personal data provided during the registration process for
                  the Service and the eventual subscription to the contract with
                  the Data Controller, as well as their subsequent updates, are
                  necessary for the performance of the contract with the Data
                  Subject and for the fulfillment of legal obligations,
                  including tax obligations, by the Data Controller. The legal
                  basis for the processing of such data is therefore provided by
                  Article 6, letter b) (contract) and letter c) (obligation of
                  the Data Controller) of the GDPR.
                </p>
              </li>
              <li class="ml-4 p-1">
                <p>
                  The same data may also be used by the Data Controller to
                  contact the Data Subject with direct commercial offers related
                  to the services used by the Data Subject or similar services.
                  In this case, the legal basis for the processing is provided
                  by Article 6, letter f) (legitimate interest) of the GDPR. The
                  Data Subject may always object to this processing by
                  submitting a written request to the Data Controller.
                </p>
              </li>
              <li class="ml-4 p-1">
                <p>
                  The same data may also be used by the Data Controller for (i)
                  indirect commercial offers and (ii) profiling, including the
                  processing of studies, statistical research, and market
                  analysis. These processing activities will be carried out only
                  with the explicit and specific consent of the Data Subject,
                  and the legal basis for the processing is therefore provided
                  by Article 6, letter a) of the GDPR. The Data Subject may
                  always revoke such consents by submitting a written request to
                  the Data Controller.
                </p>
              </li>
              <li class="ml-4 p-1">
                <p>
                  The personal data provided during the use of the Service are
                  necessary for the performance of the contract with the Data
                  Subject, particularly for document indexing and response to
                  the User's Queries. The legal basis for this processing is
                  provided by Article 6, letter b) (contract) of the GDPR.
                </p>
              </li>

              <li class="ml-4 p-1">
                <p>
                  The same data may also be used for the training of artificial
                  intelligence algorithms employed by the Data Controller,
                  subject to the explicit and specific consent of the Data
                  Subject. The legal basis for this processing is provided by
                  Article 6, letter a) of the GDPR. The Data Subject may always
                  revoke such consents by submitting a written request to the
                  Data Controller.
                </p>
              </li>
            </ol>
          </div>

          <div>
            <span class="h5">Processing Methods</span>
            <p class="p-1">
              The processing will be carried out in both automated and manual
              ways, in compliance with the provisions of the GDPR regarding
              security measures, by specifically authorized individuals.
            </p>
          </div>

          <div>
            <span class="h5">Data Retention </span>
            <p class="p-1">
              The collected personal data will be stored for the period strictly
              necessary to achieve the purposes indicated in this privacy
              policy, for which they are collected and processed, without
              prejudice to the Data Subject’s rights that can be exercised under
              the GDPR.
            </p>
            <p class="px-1">
              With regard to personal data potentially contained in the
              electronic documents uploaded during the use of the Service, they
              will be deleted thirty days after the User removes them from the
              platform, in order to allow the User to recover the document, if
              requested. However, the User has the right to request to the Data
              Controller the immediate deletion of the data.
            </p>
            <p class="px-1">
              All other personal data will be retained for the entire duration
              of the contract and for the subsequent period necessary to protect
              the rights arising from the contract and to fulfill the legal
              obligations of the Data Controller.
            </p>
            <p class="px-1">
              The retention period mentioned above includes a period of one year
              from the end of the contractual relationship with the Data Subject
              to ensure the proper application of any promotional mechanisms
              granted, limited to the data necessary for such purpose, including
              the Data Subject's email address.
            </p>
            <p class="px-1">
              With the express and specific consent, personal data may also be
              retained, regardless of further requirements, for two years after
              the termination of the contractual relationship, renewable with a
              new expression of consent, for direct marketing purposes.
            </p>
          </div>

          <div>
            <span class="h5">Territorial Scope</span>
            <p class="p-1">
              The collected data may be transferred to countries located outside
              the European Union only if the security of the transfer and
              subsequent processing is fully guaranteed at levels comparable to
              those established by the GDPR, particularly through the use of
              agreements that incorporate the Standard Contractual Clauses
              (SCCs) adopted with the Commission Implementing Decision (EU)
              2021/914 of June 4, 2021, and subsequent modifications and
              updates, or any other method or tool permitted by the applicable
              regulations to ensure the mentioned security.
            </p>
          </div>

          <div>
            <span class="h5">Rights of the Data Subject</span>
            <ol type="a" class="list-padding">
              <span>
                The Data Subject, at any time, with regard to the provided
                personal data, has the right to:
              </span>

              <li class="ml-4 p-1">access and request a copy of the data</li>
              <li class="ml-4 p-1">
                request rectification and updating of the data;
              </li>
              <li class="ml-4 p-1">
                request erasure or restriction of processing, where possible, of
                the data;
              </li>
              <li class="ml-4 p-1">request data portability;</li>
              <li class="ml-4 p-1">
                object to processing and withdraw previously given consents;
              </li>
              <li class="ml-4 p-1">
                request further information about the aspects of this privacy
                policy;
              </li>
              <li class="ml-4 p-1">
                lodge a complaint with the supervisory authority (Garante per la
                Privacy).
              </li>
              <span
                >These rights can be exercised by submitting a written request
                to the Data Controller (except for letter g).
              </span>
            </ol>
          </div>

          <div>
            <span class="h5">Cookies</span>
            <p class="p-1">
              The website of the Service uses cookies (technical tools
              consisting of text strings stored on the User's device) that may
              contain personal data. These cookies can be of a technical nature
              - and their use is therefore necessary for the browsing of the
              website regardless of the consent of the Data Subject – or they
              can be of analytical or profiling nature, in which case their
              creation and use will occur only with the prior consent of the
              Data Subject. For further details regarding the purposes, legal
              bases, retention periods, and rights of the Data Subject
              concerning cookies, please refer to the cookie policy on the
              website.
            </p>
          </div>
        </v-card-text>
        <v-card-actions v-if="history">
          <v-btn
            class="m-auto"
            variant="flat"
            color="button-primary"
            alt="Close"
            @click="this.$router.back()"
          >
            {{ $t("legenda_196") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PrivacyComponentEn",
  computed: {
    history() {
      const state = window.history.state.back;
      return state;
    },
  },
};
</script>
