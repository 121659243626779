<template>
  <v-container fluid class="h-100 m-0 p-0">
    <v-row class="bg-page-primary h-100 m-0 overflow-hidden">
      <v-col cols="11" class="d-flex flex-column mx-auto pt-3 pb-1 mx-2 my-1">
        <!-- upload -->
        <div class="mx-4 px-3">
          <v-alert
            theme="dark"
            color="typo-ternary"
            class="justify-content-center"
          >
            <template v-slot:default>
              <span class="my-3 py-2">{{ $t("legenda_007", 2) }}</span>
              <v-btn
                append-icon="mdi-arrow-right"
                :title="$t('legenda_008')"
                color="button-ternary"
                variant="elevated"
                class="text-none mx-3 py-1 text-decoration-none"
                href="https://legenda-app.com/"
                target="_blank"
              >
                {{ $t("legenda_008") }}
              </v-btn>
            </template>
          </v-alert>
        </div>
        <div class="flex-grow-1">
          <tabs
            :tabs="dashboardTabs"
            v-if="currentFolderId"
            class="my-1 mx-4 h-100"
          ></tabs>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TabsComponent from "@/components/TabsComponent.vue";
import app from "../../../main.js";

export default {
  name: "OlympialexDashboardView",

  components: {
    tabs: TabsComponent,
  },
  data() {
    return {
      homeFolder: this.$t("legenda_024"),
      dashboardTabs: [
        {
          id: 0,
          descr: "documents",
          title: "legenda_048",
          component: {
            path: "views/dashboard/components/dashboardTabs/document/olympialex",
            name: "OlympialexDocumentsTabComponent",
            componentProps: {},
          },
        },
        {
          id: 1,
          descr: "searches",
          title: "legenda_049",
          component: {
            path: "views/dashboard/components/dashboardTabs/query",
            name: "SearchQueriesTabComponent",
            componentProps: {},
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentFolderId: "stateCurrentFolderId",
      homeId: "stateHomeId",
    }),
  },
  props: {
    folder_id: {
      type: Number,
      default: null,
    },
  },

  methods: {
    ...mapActions(["home", "initTree"]),
    /**
     *
     */
    calcHeight() {
      const height =
        window.innerHeight -
        // navbar
        64 -
        // p-4
        1.5 * 16 * 3 -
        // buttons
        180;
      return height;
    },
  },
  async created() {
    this.dashboardTabs[1].component.componentProps.height = this.calcHeight();
    this.dashboardTabs[0].component.componentProps.height =
      this.calcHeight() - 235;
    app.component("DocumentsTabComponent");
    app.component("SearchQueriesTabComponent");
    this.folderContent = null;
    //this.$store.commit("setSearchResults", null);
    if (this.folder_id) {
      this.$store.commit("setCurrentFolderId", this.folder_id);
    } else {
      const home = await this.home();
      this.initTree({ itemId: this.homeId, status: home.status });
    }
  },
};
</script>
