<template>
  <v-container fluid class="h-100 m-0 p-0">
    <v-row class="bg-page-secondary text-contrast">
      <v-col class="m-auto p-auto" cols="12" sm="10" xs="12">
        <div
          class="text-h6 text-md-h5 text-lg-h4 legenda-flex-column align-items-center p-2 m-auto text-center"
        >
          <span> {{ $t("legenda_145") }} </span>
          <span> {{ $t("legenda_146") }} </span>
        </div>

        <div style="font-size: 10px !important" class="p-2">
          <stripe-pricing-table
            :pricing-table-id="pricingTableId"
            :publishable-key="publishableKey"
          >
          </stripe-pricing-table>
        </div>
        <div
          class="legenda-flex-column text-center p-2 m-auto"
          style="width: 60%"
        >
          <span> {{ $t("legenda_218") }} </span>
        </div>
        <div class="p-2">
          <metis_footer></metis_footer>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FooterComponent from "../components/FooterComponent.vue";
export default {
  name: "SubscriptionView",
  components: {
    metis_footer: FooterComponent,
  },
  computed: {
    pricingTableId() {
      return process.env.VUE_APP_STRIPE_PRICING_TABLE_ID;
    },
    publishableKey() {
      return process.env.VUE_APP_STRIPE_PK;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
