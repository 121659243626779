import { createApp } from "vue";
import App from "./App.vue";

// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "@mdi/font/css/materialdesignicons.css";

// vuetify
import { vuetifyInstance } from "./plugins/vuetify/vuetify.js";
// important; load styles after instance has been created
import "./assets/styles/fonts.css";
import "./assets/styles/global.css";
import "vue3-tree-vue/dist/style.css";
// store
import store from "./store";

// i18n
import { i18nInstance } from "./translations/i18n.js";
import { languageService } from "./services/languageService.js";

// router
import router from "./router";

// plausible
import { plausible } from "./plugins/plausible/plausible.js";

// head
import { head } from "./plugins/head/head.js";

// filters
import { formatLink } from "./filters/templateFilters";
import { hasView } from "./filters/accessViewFilter";
import { deepSearch, sortFolderContent } from "./filters/treeFilter";

import "vue3-tree-vue/dist/style.css";
// create Legenda application
const app = createApp(App);

// global app configuration
app.config.globalProperties.$filters = {
  formatLink,
  hasView,
  deepSearch,
  sortFolderContent,
};
app.config.errorHandler = (err, vm, info) => {
  console.debug("Error:", err);
  console.debug("Vue component:", vm);
  console.debug("Additional info:", info);
};

// use all modules
app.use(vuetifyInstance);
app.use(store);
app.use(i18nInstance);
app.use(router);
app.use(plausible);
app.use(head);

//mount app once the translations have been loaded
languageService
  .loadStartUpMessages(
    i18nInstance,
    i18nInstance.global.locale.value,
    i18nInstance.global.fallbackLocale.value
  )
  .then(() => {
    app.mount("#app");
  })
  .catch(() => {});

export default app;
