
  /**
   * Method to get a file size object based on the original size in bytes.
   * 
   * @param {*} sizeInBytes 
   * @param {*} metric : If optional paramter metric is passed, we get the size in the desired metric (Bytes, KB, etc.)
   * @param {*} decimalPoint: decimal point accurracy
   * @returns 
   */
  function getFileSize(sizeInBytes, metric, decimalPoint=2) {
    if(sizeInBytes == 0) return '0 Bytes';

    const k = 1024,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
    let i = Math.floor(Math.log(sizeInBytes) / Math.log(k));

    if(metric){
      i = sizes.findIndex( size =>  size.toLowerCase() === metric.toLowerCase());
    }

    const exactSize = parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(decimalPoint));  

    // format the result 
    const completeSize =  exactSize + ' ' + sizes[i];
    const fileSizeObject = {
        size: exactSize,
        metric: sizes[i],
        completeSize: completeSize,
      };
    return fileSizeObject;    
  }

  export {getFileSize}