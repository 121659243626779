<template>
  <div>
    <v-alert
      :type="this.variant"
      :closable="closable"
      :density="density"
      v-model="alert"
      :color="color"
    >
      <template v-slot:text>
        <div v-for="message in messageList" :key="message">
          <p
            class="m-0"
            :class="{ 'p-1': messageList && messageList.length > 1 }"
            v-if="message.links"
            v-html="constructTranslationString(message.text, message.links)"
          ></p>
          <p
            class="m-0"
            :class="{ 'p-1': messageList && messageList.length > 1 }"
            v-if="!message.links"
          >
            {{ $t(message.text) }}
          </p>
        </div>
      </template>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    messageList: null,
    variant: null,
    link: null,
    closable: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: "default",
    },
    color: {
      type: String,
      default: null,
    },
  },
  watch: {
    link: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        console.log(newValue);
      },
      deep: true,
    },
  },
  methods: {
    constructTranslationString(text, links) {
      const formattedLinks = [];
      // build htlm tag for each link
      links.forEach((link) => {
        link.text = this.$t(link.placeholder);
        const formattedLink = this.$filters.formatLink(link);
        formattedLinks.push(formattedLink);
      });

      const spreadedLinks = [...formattedLinks.map((link) => link.tag)];
      // pass links with list interpolation
      return this.$t(text, spreadedLinks);
    },
  },
  data() {
    return {
      alert: true,
    };
  },
};
</script>
