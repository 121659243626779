<template>
  <div>
    <tcIt v-if="languageReactive === 'it'"></tcIt>
    <tcEn v-else></tcEn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TermsConditionsComponentIt from "./it/TermsConditionsComponent.vue";
import TermsConditionsComponentEn from "./en/TermsConditionsComponent.vue";

export default {
  name: "PrivacyView",
  components: {
    tcIt: TermsConditionsComponentIt,
    tcEn: TermsConditionsComponentEn,
  },
  computed: {
    ...mapGetters({ language: "language" }),
  },
  watch: {
    language(value) {
      this.languageReactive = value;
    },
  },
  data() {
    return {
      languageReactive: null,
    };
  },
  created() {
    this.languageReactive = this.language ?? localStorage.getItem("language");
  },
};
</script>
