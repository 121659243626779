<template>
  <baseSearchResultsView
    v-model="componentConfig"
    :statusAlertMessage="componentConfig.statusAlertMessage()"
    :createPayload="componentConfig.createPayload"
    :documentId="documentId"
    :showStatusAlert="showStatusAlertReactive"
  />
</template>

<script>
import configFactory from "./services/inspectSearchConfiguration.js";
import BaseSearchResults from "./BaseSearchResults.vue";
import { mapGetters } from "vuex";

export default {
  name: "SearchResults",

  components: {
    baseSearchResultsView: BaseSearchResults,
  },
  computed: {
    ...mapGetters({
      searchScope: "stateSearchScope",
      tree: "stateTree",
    }),
  },
  watch: {
    /**
     *
     */
    progress() {
      this.setStatusAlert(this.searchScope[0]);
    },
    /**
     *
     */
    searchScope: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.documentId =
            newValue[0].type === "document" ? newValue[0].itemId : "";
          this.componentConfig = configFactory.createConfig(this.documentId);
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      showStatusAlertReactive: false,
      componentConfig: null,
      documentId: null,
    };
  },
  methods: {
    /**
     *
     */
    setStatusAlert(scope) {
      this.showStatusAlertReactive =
        this.componentConfig.setShowStatusAlert(scope);
    },

    /**
     *
     */
    setScopeForDocument() {
      // before we go, reset the scope to the folderToSearch if the the new scope is still a document
      if (
        this.searchScope.length > 0 &&
        this.searchScope[0].type == "document"
      ) {
        const newScope = this.$filters.deepSearch(
          this.tree,
          this.searchScope[0].parentId,
          "itemId",
          "children"
        );
        this.$store.commit("setSearchScope", newScope);
      }
    },
  },

  created() {
    // created methods according to the search mode
    this.documentId = window.history.state.document_id;
    this.componentConfig = configFactory.createConfig(this.documentId);
  },

  mounted() {
    this.setStatusAlert(this.searchScope[0]);
  },
  beforeRouteLeave(to, from, next) {
    // files are kept in local storage with their initial config (scale, page etc)
    // remove after exiting pdf explorer to avoid overriding existing configs
    // a file that has been opened normally and after search is recognised as the same from the pdf viewer
    // so we need to override its scale; too much, therefore, clear storage and start new depending on launch mode (normal /highlight)
    this.$store.commit("setPdfBinary", new ArrayBuffer(0)); //take out
    localStorage.removeItem("pdfjs.history");
    if (!["SearchResults", "DocumentExplorer"].includes(to.name)) {
      this.setScopeForDocument();
    }
    next();
  },
};
</script>
