import AxiosSingleton from "./axiosSingleton";

class AxiosWrapper {
  /** */
  constructor() {
    const singleton = new AxiosSingleton();
    this.axiosInstance = singleton.getAxiosInstance();
  }
  /**
   *
   * @param {*} url
   * @param {*} parameters
   * @param {*} headers
   * @returns
   */
  async get(url, urlParameters, searchParameters, headers, responseType) {
    let searchParams = null;
    // build search params if any
    if (searchParameters) {
      // encodes properly the parameters
      searchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(searchParameters)) {
        searchParams.append(key, value);
      }
    }

    return await this.axiosInstance.get(url, {
      urlParams: urlParameters,
      params: searchParams,
      headers: headers,
      responseType: responseType,
    });
  }
  /**
   *
   * @param {*} url
   * @param {*} parameters
   * @param {*} headers
   * @returns
   */
  async post(url, payloadData, parameters, responseType) {
    return await this.axiosInstance.post(url, payloadData, {
      urlParams: parameters,
      headers: { "Content-Type": "application/json" },
      responseType: responseType,
    });
  }

  /**
   *
   * @param {*} url
   * @param {*} parameters
   * @param {*} headers
   * @returns
   */
  async postForm(url, formData, parameters, responseType) {
    return await this.axiosInstance.postForm(url, formData, {
      urlParams: parameters,
      headers: { "Content-Type": "multipart/form-data" },
      responseType: responseType,
    });
  }
  /**
   *
   * @param {*} url
   * @param {*} parameters
   * @param {*} headers
   * @returns
   */
  async delete(url, urlParameters, searchParameters, headers) {
    let searchParams = null;
    // build search params if any
    if (searchParameters) {
      // encodes properly the parameters
      searchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(searchParameters)) {
        searchParams.append(key, value);
      }
    }
    return await this.axiosInstance.delete(url, {
      urlParams: urlParameters,
      params: searchParams,
      headers: headers,
    });
  }
}

const axiosWrapper = new AxiosWrapper();
export default axiosWrapper;
