<template>
  <div class="align-consent">
    <v-list class="align-list" lines="one">
      <span class="text-h6">{{ $t("legenda_083") }}</span>
      <v-list-item class="py-1 list-item-compact">
        <v-list-item-action>
          <v-checkbox-btn
            class="pe-2"
            :model-value="legal"
            required="true"
            @click="$emit('legal', !legal)"
          ></v-checkbox-btn>
          <div
            v-html="
              $t('legenda_147', [
                this.$filters.formatLink({
                  href: termsPage,
                  openInTab: true,
                  customClass: 'text-typo-blue',
                  text: $t('legenda_083'),
                }).tag,
              ])
            "
          ></div>
        </v-list-item-action>
      </v-list-item>
      <v-list-subheader>
        <i class="subheader-wrap"> {{ $t("legenda_148") }} </i>
      </v-list-subheader>
    </v-list>

    <v-list class="align-list" lines="three">
      <span class="text-h6">{{ $t("legenda_014") }}</span>
      <v-list-subheader>
        <div
          class="subheader-wrap"
          v-html="
            $t('legenda_149', [
              this.$filters.formatLink({
                href: privacyPage,
                placeholder: 'legenda_014',
                openInTab: true,
                customClass: 'text-typo-blue',
                text: $t('legenda_014'),
              }).tag,
            ])
          "
        ></div>
      </v-list-subheader>

      <v-list-item class="py-1 list-item-compact">
        <v-list-item-action>
          <v-checkbox-btn
            :model-value="marketing"
            class="pe-2"
            @change="$emit('marketing', !marketing)"
          ></v-checkbox-btn>
          <div
            v-html="
              $t('legenda_150', [
                this.$filters.formatLink({
                  href: privacyPage,
                  placeholder: 'legenda_014',
                  openInTab: true,
                  customClass: 'text-typo-blue',
                  text: $t('legenda_014'),
                }).tag,
              ])
            "
          ></div>
        </v-list-item-action>
      </v-list-item>

      <v-list-item class="py-1 list-item-compact">
        <v-list-item-action>
          <v-checkbox-btn
            :model-value="profiling"
            class="pe-2"
            @click="$emit('profiling', !profiling)"
          ></v-checkbox-btn>
          <div
            v-html="
              $t('legenda_151', [
                this.$filters.formatLink({
                  href: privacyPage,
                  placeholder: 'legenda_014',
                  openInTab: true,
                  customClass: 'text-typo-blue',
                  text: $t('legenda_014'),
                }).tag,
              ])
            "
          ></div>
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="py-1 list-item-compact">
        <v-list-item-action>
          <v-checkbox-btn
            :model-value="training"
            class="pe-2"
            @click="$emit('training', !training)"
          ></v-checkbox-btn>
          <div
            v-html="
              $t('legenda_152', [
                this.$filters.formatLink({
                  href: privacyPage,
                  placeholder: 'legenda_014',
                  openInTab: true,
                  customClass: 'text-typo-blue',
                  text: $t('legenda_014'),
                }).tag,
              ])
            "
          ></div>
        </v-list-item-action>
      </v-list-item>
      <v-list-subheader>
        <i class="subheader-wrap">{{ $t("legenda_153") }} </i>
      </v-list-subheader>
    </v-list>
  </div>
</template>
<script>
export default {
  name: "UserConsentComponent",

  data() {
    return {};
  },
  props: {
    legal: {
      type: Boolean,
      default: false,
    },
    marketing: {
      type: Boolean,
      default: false,
    },
    profiling: {
      type: Boolean,
      default: false,
    },
    training: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     *
     */
    termsPage() {
      return "/terms_conditions";
    },
    /**
     *
     */
    privacyPage() {
      return "/privacy_policy";
    },
  },
};
</script>
<style>
.align-consent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 0;
}
.align-list {
  width: -webkit-fill-available;
  text-wrap: wrap;
  text-align: justify;
}
.required:after {
  content: " *";
  color: red;
}
.list-item-compact {
  min-height: fit-content !important;
  font-size: 0.9rem;
}
.subheader-wrap {
  text-wrap: wrap;
}
</style>
