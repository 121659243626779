<template>
  <div class="video-wrapper">
    <div class="video-root">
      <div class="video-background"></div>

      <div
        class="imageDashboardRight-wrapper"
        style="transform: translateX(-50%)"
      >
        <div class="image-position">
          <v-img
            class="imageDashboardRight-style"
            :src="require('../../../../assets/images/imageDashboardLeft.png')"
          ></v-img>
        </div>
      </div>

      <div
        class="imageDashboardLeft-wrapper"
        style="transform: translateX(-50%)"
      >
        <div class="image-position">
          <v-img
            class="imageDashboardLeft-style"
            :src="require('../../../../assets/images/imageDashboardLeft.png')"
          ></v-img>
        </div>
      </div>

      <div class="video-container">
        <div class="animation">
          <video
            autoplay
            muted
            loop
            class="video-layout"
            :src="require('../../../../assets/images/legenda_usage_video.mp4')"
            type="video/mp4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoComponent",
};
</script>

<style>
.video-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem;
}
.video-root {
  flex: none;
  height: 694px;
  overflow: hidden;
  position: relative;
  transform: perspective(1200px);
  width: 100%;
}
.video-background {
  background-color: #f1ff82;
  border-radius: 32px;
  flex: none;
  height: 697px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.video-container {
  overflow: hidden !important;
  border-color: #d7d9c2;
  border-bottom-width: 4px;
  border-top-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-style: solid;
  align-content: center;
  align-items: center;
  border-radius: 16px;
  display: flex;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: center;
  left: 50%;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 1031px;
}
.animation {
  flex: none;
  height: -webkit-fill-available;
  position: relative;
  width: 100%;
}
.video-layout {
  cursor: auto;
  width: 100%;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0);
  object-position: 50% 50%;
}
.imageDashboardLeft-wrapper {
  overflow: hidden !important;
  border-color: #d7d9c2;
  border-bottom-width: 4px;
  border-top-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-style: solid;
  aspect-ratio: 1.47005 / 1;
  border-radius: 16px;
  flex: 0 0 auto;
  left: 65%;
  opacity: 0.8;
  position: absolute;
  top: 184px;
  transform: translateX(-50%);
  width: 812px;
}
.image-position {
  position: absolute;
  border-radius: inherit;
  inset: 0px;
  align-self: baseline;
  width: 100%;
}
.imageDashboardLeft-style {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: right top;
  object-fit: contain;
  image-rendering: auto;
}
.imageDashboardRight-wrapper {
  overflow: hidden !important;
  border-color: #d7d9c2;
  border-bottom-width: 4px;
  border-top-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-style: solid;
  aspect-ratio: 1.47005 / 1;
  border-radius: 16px;
  bottom: -53px;
  flex: 0 0 auto;
  left: 35%;
  opacity: 0.8;
  position: absolute;
  top: 184px;
  transform: translateX(-50%);
  width: var(--framer-aspect-ratio-supported, 828px);
}
.imageDashboardRight-style {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: left top;
  object-fit: cover;
  image-rendering: auto;
}

@media (max-width: 809px) {
  .video-root {
    height: 199px;
  }
  .video-background {
    border-radius: 12px;
    height: 204px;
  }
  .imageDashboardLeft-wrapper,
  .imageDashboardRight-wrapper {
    opacity: 0;
    overflow: hidden;
  }
  .video-container {
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: 4px;
    top: 55%;
    width: 325px;
  }
  .video-wrapper {
    padding: 1rem;
  }
  .animation {
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: 4px;
    top: 55%;
  }
  .video-layout {
    border-radius: 4px;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .video-wrapper {
    padding: 2rem;
  }
  .video-root {
    height: 589px;
  }

  .imageDashboardLeft-wrapper {
    opacity: 0;
    overflow: hidden;
  }
  .imageDashboardRight-wrapper {
    opacity: 0;
    overflow: hidden;
  }
  .video-container {
    top: 60%;
    width: 909px;
  }
  .animation {
    height: 516px;
  }
}
@media (min-width: 810px) and (max-width: 1023px) {
  .video-wrapper {
    padding: 2rem;
  }
  .video-root {
    height: 445px;
  }
  .imageDashboardLeft-wrapper {
    opacity: 0;
    overflow: hidden;
  }
  .imageDashboardRight-wrapper {
    opacity: 0;
    overflow: hidden;
  }
  .video-container {
    border-bottom-width: 2px;
    border-top-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    top: 59%;
    width: 678px;
  }
  .animation {
    height: 100%;
  }
}
</style>
