<template>
  <div class="signature-wrapper">
    <div class="signature-intro">
      <div class="p-2">
        {{ $t("legenda_203") }}
        <v-img :src="logo" width="110px"></v-img>
      </div>
    </div>
  </div>
</template>
<script>
import metisLogoBlack from "../../assets/logo/metis_logo_black.png";
import metisLogoWhite from "../../assets/logo/metis_logo_white.png";
export default {
  name: "SignatureComponent",
  props: {
    logoColor: {
      type: String,
      default: "black",
    },
  },
  computed: {
    logo() {
      return this.logoColor == "black" ? metisLogoBlack : metisLogoWhite;
    },
  },
};
</script>
<style>
.signature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 48px;
}
.signature-intro {
  max-width: 600px;
  text-align: -webkit-center;
}
</style>
