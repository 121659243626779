<template>
  <v-row class="bg-page-primary m-0 h-100">
    <v-col class="d-flex flex-column mx-auto pt-5 justify-space-between">
      <div class="p-1 mx-auto" style="max-width: 600px">
        <div class="my-2">
          <div class="text-h34">{{ $t("legenda_011") }}</div>
          <hr />
        </div>
        <div class="my-3 py-2">
          <div class="legenda-flex-column">
            <div class="legenda-flex-row justify-space-between">
              <p>
                <strong>{{ $t("legenda_012") }}&nbsp;</strong>
              </p>

              <p>
                <span>{{ user.username }}</span>
              </p>
            </div>
            <div
              class="legenda-flex-row justify-space-between"
              style="align-items: flex-end"
            >
              <p>
                <strong>{{ $t("legenda_193") }}&nbsp;</strong>
              </p>

              <div>
                <v-switch
                  v-model="llmSearch"
                  color="secondary"
                  hide-details
                  @change="onLlmSettingChanged(llmSearch)"
                ></v-switch>
              </div>
            </div>

            <v-divider style="background-color: black;"></v-divider>

            <v-snackbar
              class="elevation-0"
              v-model="showStatusAlert"
              :location="'top'"
              :multi-line="true"
              color="primary-lighten-3"
              :timeout="-1"
            >
              {{ $t(alertMessage) }}
              <template v-slot:actions>
                <v-btn class="mx-1" flat @click="showStatusAlert = false">
                  {{ $t("legenda_040") }}
                </v-btn>
              </template>
            </v-snackbar>

            <v-snackbar
              class="elevation-0"
              v-model="changePassSuccessAlert"
              :location="'top'"
              :multi-line="true"
              color="success"
              :timeout="-1"
            >
              {{ $t("legenda_206") }}
              <template v-slot:actions>
                <v-btn class="mx-1" flat @click="changePassSuccessAlert = false">
                  {{ $t("legenda_040") }}
                </v-btn>
              </template>
            </v-snackbar>

            <div class="legenda-flex-row justify-space-between"
                 style="align-items: flex-end">
              <v-text-field
                  :type="showCurrentPass ? 'text' : 'password'"
                  v-model="currentPassword"
                  variant="underlined"
                  label="Current password"
                  required
                  :rules="[rules.required, rules.length]"
                  :append-icon="passwordLength"
                  :append-inner-icon="showCurrentPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showCurrentPass = !showCurrentPass"
                ></v-text-field>
            </div>
            <div class="legenda-flex-row justify-space-between"
                 style="align-items: flex-end">
              <v-text-field
                  :type="showNewPass ? 'text' : 'password'"
                  v-model="newPassword"
                  variant="underlined"
                  label="New password"
                  required
                  :rules="[rules.required, rules.length]"
                  :append-icon="passwordLength"
                  :append-inner-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showNewPass = !showNewPass"
                ></v-text-field>
            </div>
            <div class="legenda-flex-row justify-space-between"
                 style="align-items: flex-end">
              <v-text-field
                  :type="showConfirmNewPass ? 'text' : 'password'"
                  v-model="confirmNewPassword"
                  variant="underlined"
                  label="Confirm new password"
                  required
                  :rules="[rules.required, rules.length]"
                  :append-icon="passwordLength"
                  :append-inner-icon="showConfirmNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showConfirmNewPass = !showConfirmNewPass"
                ></v-text-field>
            </div>

            <div style="text-align:center">
              <v-btn
              color="button-primary"
              class="link-button m-auto text-decoration-none"
              variant="elevated"
              @click="onChangePasswordClick"
              >Change password</v-btn
              >
            </div>

            <v-divider style="background-color: black;"></v-divider>

            <div
              v-if="showSubscriptionEnd"
              class="legenda-flex-row justify-space-between"
            >
              <p>
                <strong>{{ $t("legenda_205") }}&nbsp;</strong>
              </p>

              <p>
                <span>{{ formattedSubscriptionEnd }}</span>
              </p>
            </div>
          </div>

          <div
            class="d-flex flex-column py-3 m-auto"
            v-if="
              this.$filters.hasView(
                'customerPortalView',
                user.subscription_tier
              )
            "
          >
            <span class="mx-auto my-3">{{ $t("legenda_037") }}</span>

            <v-btn
              color="button-primary"
              class="link-button m-auto text-decoration-none"
              variant="elevated"
              :href="customerPortal"
              >{{ $t("legenda_038") }}</v-btn
            >
          </div>

          <div class="d-flex justify-content-center p-1 m-3">
            <p
              v-html="
                constructTranslationString('legenda_154', [
                  {
                    href: feedbackLink,
                    placeholder: 'legenda_133',
                    openInTab: false,
                    customClass: 'text-typo-blue',
                  },
                ])
              "
            ></p>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <metis_footer />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FooterComponent from "../components/FooterComponent.vue";

export default {
  name: "ProfileView",

  components: {
    metis_footer: FooterComponent,
  },

  methods: {
    ...mapActions([
      "changePassword",
    ]),

    async onChangePasswordClick() {
      try {
        await this.changePassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          confirmNewPassword: this.confirmNewPassword,
        })

        this.changePassSuccessAlert = true;
      } catch (error) {
        this.alertMessage = error.response.data.detail;
        this.showStatusAlert = true;
      }
    },

    onLlmSettingChanged(value) {
      this.$store.commit("setLlmSearchOption", value);
      localStorage.setItem("llmOption", value);
    },
    constructTranslationString(text, links) {
      const formattedLinks = [];
      // build htlm tag for each link
      links.forEach((link) => {
        link.text = this.$t(link.placeholder);
        const formattedLink = this.$filters.formatLink(link);
        formattedLinks.push(formattedLink);
      });

      const spreadedLinks = [...formattedLinks.map((link) => link.tag)];
      // pass links with list interpolation
      return this.$t(text, spreadedLinks);
    },
  },

  computed: {
    ...mapGetters({
      user: "stateUser",
      olympiaLexView: "olympiaLexView",
      llmSearchOption: "llmSearchOption",
    }),
    /**
     *
     */
    customerPortal() {
      // encode email: foo.bar_baz+10@bar.com -> foo.bar_baz%2B10%40bar.com
      const encodedUsername = encodeURIComponent(this.user.username);
      return `${process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${encodedUsername}`;
    },
    /**
     *
     */
    feedbackLink() {
      return this.$root.$i18n.locale === "it"
        ? "https://airtable.com/appZg28lIhGyNNoJ7/shrv8YCtIAHKP7L8M"
        : "https://airtable.com/shr0Djx63CIENR2Zh";
    },
    /**
     *
     */
    formattedSubscriptionEnd() {
      const date = new Date(this.user.subscription_end);
      return isNaN(date.getTime()) ? "-" : date.toLocaleDateString("en-GB");
    },
    showSubscriptionEnd() {
      return (
        this.$filters.hasView(
          "subscriptionEndView",
          this.user.subscription_tier
        ) || this.formattedSubscriptionEnd !== "31/12/2099"
      );
    },
  },

  data() {
    {
      return {
        llmSearch: false,
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        showStatusAlert: false,
        alertMessage: "",
        rules: {
          required: (value) => !!value || this.$t("legenda_104"),
          passwordValidation: () =>
            this.checkPassword() || this.$t("legenda_105"),
          length: (value) => value.length > 7 || this.$t("legenda_106"),
        },
        showCurrentPass: false,
        showNewPass: false,
        showConfirmNewPass: false,
        changePassSuccessAlert: false
      };
    }
  },
  mounted() {
    this.llmSearch = this.llmSearchOption;
  },
};
</script>
<style></style>
