<template>
  <v-footer class="bg-footer-primary w-100 legenda-flex-column p-0">
    <div class="legenda-flex-row legenda-info">
      <div class="legenda-flex-column metis-info">
        <div>
          <v-img :src="logo" max-width="140px"></v-img>
        </div>
        <div>
          <span>Metis S.r.l. Piazza Sicilia 6, 20146 Milano</span>
        </div>
      </div>

      <div class="legenda-flex-column text-primary-lighten-2 contact">
        <div class="legenda-flex-column">
          <span class="footer-social">{{ $t("legenda_191", 2) }}</span>
          <span
            ><a class="text-white" href="mailto:info@metisai.it"
              >info@metisai.it</a
            ></span
          >
        </div>

        <div class="social flex-column-footer">
          <span class="footer-social"> {{ $t("legenda_192") }}</span>
          <a target="_blank" href="https://www.linkedin.com/company/metissrl">
            <v-img :src="linkedinSvg" max-width="30px"></v-img>
          </a>
        </div>
      </div>
    </div>

    <v-divider></v-divider>

    <div class="impressum bg-footer-secondary text-center">
      <span>
        © 2024 © Metis S.r.l. — P.IVA/CF: 12217420962 — Sede legale: Piazza
        Sicilia 6, 20146 Milano, REA: 2647101 — PEC: 1metissrl@legalmail.it —
        Capitale versato: €10.941,15
      </span>
    </div>
  </v-footer>
</template>
<script>
import linkedInSvg from "../../assets/svg/linkedin.svg";
import legendaLogo from "../../assets/images/legenda-logo-yellow.svg";
export default {
  name: "FooterComponent",
  data() {
    return {
      linkedinSvg: linkedInSvg,
      logo: legendaLogo,
    };
  },
};
</script>

<style>
.legenda-info {
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 38px 120px;
}
.impressum {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px;
}
.metis-info {
  justify-content: flex-start;
  gap: 32px;
}
.contact {
  gap: 48px;
}
.footer-social {
  font-size: larger;
  font-weight: 700 !important;
  padding: 10px 0;
}
@media (max-width: 810px) {
  .legenda-info {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 38px 12px;
  }
}
</style>
