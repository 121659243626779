<template>
  <div v-if="loading" class="overlay">
    <div>
      <v-progress-circular
        class="spinner"
        model-value="20"
        :size="70"
        :width="8"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SpinnerComponent",
  computed: {
    ...mapGetters({ loading: "stateIsLoading" }),
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  cursor: pointer;
}
.spinner {
  top: 50vh;
  transform: translateY(-50%);
  left: 50vw;
  transform: translateX(-50%);
}
</style>
