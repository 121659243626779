<template>
  <v-container>
    <v-row class="justify-center bg-color-transparent">
      <signature class="p-1" :logoColor="customColor"></signature>
    </v-row>

    <v-row class="justify-center">
      <p>
        <router-link :class="this.fontColor" to="/terms_conditions">
          {{ $t("legenda_013") }}
        </router-link>
        |
        <router-link :class="this.fontColor" to="/privacy_policy">
          {{ $t("legenda_014") }}
        </router-link>
      </p>
    </v-row>
  </v-container>
</template>

<script>
import SignatureComponent from "./ui/SignatureComponent.vue";

export default {
  name: "FooterComponent",
  components: {
    signature: SignatureComponent,
  },
  props: {
    fontColor: {
      type: String,
      default: "text-contrast",
    },
    customColor: {
      type: String,
      default: "black",
    },
  },
};
</script>
