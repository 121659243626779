<template>
  <div>
    <div class="legenda-flex-column">
      <v-sheet
        :color="color"
        :elevation="elevation"
        class="my-2 px-3 flex-grow-1 h-100 d-flex flex-column"
        rounded="lg"
      >
        <div class="pt-2 pb-1 px-2">
          <strong
            v-if="pdfBinary.byteLength > 0"
            class="font-italic font-size-large"
            >{{ this.fileName }}
          </strong>
        </div>

        <div class="pt-1 pb-2 px-2 h-100">
          <!--pdfbinary take from state-->
          <pdfViewerCmp
            v-if="pdfBinary.byteLength > 0"
            :binaryFile="pdfBinary"
            :page="pageToShow"
            :pageScale="scale"
            v-on:pageChanged="resetPage"
          />
          <v-skeleton-loader
            v-else
            :boilerplate="boilerplate"
            style="height: 100%"
            type="subtitle, text, paragraph, paragraph, paragraph"
          ></v-skeleton-loader>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import PDFJSViewer from "@/components/document/PDFJSViewer";

export default {
  name: "DocumentViewer",
  components: {
    pdfViewerCmp: PDFJSViewer,
  },
  props: {
    fileName: {
      default: null,
      type: String,
    },
    pdfBinary: {
      type: Object,
      default() {
        return new Object();
      },
    },
    pageToShow: {
      type: Number,
      default: 1,
    },
    scale: {
      type: String,
      default: "page-fit",
    },
    boilerplate: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {};
  },
  methods: {
    resetPage() {
      this.$emit("resetPage");
    },
  },
};
</script>
<style></style>
