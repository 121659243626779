<template>
  <div class="features-wrapper">
    <div class="features-intro">
      <div class="text-h34 p-2 m-2 font-weight-bold">
        {{ $t("legenda_166") }}
      </div>
      <div class="text-h6 p-2 m-2">{{ $t("legenda_167") }}</div>
    </div>
    <div>
      <v-row class="justify-content-center">
        <v-col
          cols="12"
          xl="3"
          lg="4"
          md="4"
          class="flex-grow-1"
          v-for="feature in features"
          :key="feature.description"
        >
          <v-hover>
            <template v-slot:default="{ isHovering, props }">
              <v-card
                v-bind="props"
                :style="
                  isHovering ? ' transform: translateY(-12px);' : undefined
                "
                rounded="xl"
                class="feature-item"
                variant="outlined"
              >
                <template v-slot:title>
                  <div class="legenda-flex-column align-items-center">
                    <div class="feature-icon-style">
                      <v-img :src="feature.icon"></v-img>
                    </div>

                    <div class="feature-description-style font-weight-bold">
                      {{ $t(feature.description) }}
                    </div>
                  </div>
                </template>
                <template v-slot:text>
                  <div class="font-weight-light feature-comment-style">
                    {{ $t(feature.comment) }}
                  </div>
                </template>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import grid from "../../../../assets/svg/grid.svg";
import wand from "../../../../assets/svg/wand.svg";
import confetti from "../../../../assets/svg/confetti.svg";

export default {
  name: "FeaturesComponent",
  data() {
    return {
      features: [
        {
          description: "legenda_168",
          comment: "legenda_169",
          icon: grid,
        },
        {
          description: "legenda_170",
          comment: "legenda_171",
          icon: wand,
        },
        {
          description: "legenda_172",
          comment: "legenda_173",
          icon: confetti,
        },
      ],
    };
  },
};
</script>

<style>
.features-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem;
}
.features-intro {
  max-width: 550px;
}
.feature-tiles {
  display: flex;
  gap: 24px;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 356px;
  justify-content: center;
  max-width: 1440px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
  padding-right: 4%;
  padding-left: 4%;
}
.feature-item {
  display: flex;
  flex-direction: column;
  box-shadow: 34.854148864746094px 29.626028060913086px 48.34000015258789px
    #99999912;
  padding: 2rem;
  transition: transform 0.3s ease;
  background-color: white;
  border-color: gainsboro !important;
  flex-grow: 1;
}
.feature-icon-style {
  width: 20%;
  height: 100px;
  align-content: end;
}
.feature-description-style {
  min-height: 100px;
  align-content: center;
  max-width: 90%;
  text-wrap: wrap;
  font-weight: 900 !important;
  font-size: x-large;
}
.feature-comment-style {
  font-size: large;
  min-height: 100px;
  padding: 0 3rem 1rem 3rem;
  text-align: center;
}

@media (max-width: 550px) {
  .features-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }
}
</style>
