<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <v-row class="height h-100 m-auto">
    <v-col
      cols="12"
      lg="3"
      order="1"
      class="bg-contrast text-typo-secondary p-0 d-flex align-items-center flex-column"
      style="justify-content: space-evenly"
    >
      <div class="w-100">
        <div align-self="center" align-h="center">
          <form @submit.prevent="submit">
            <v-card flat color="transparent" class="pl-3 pr-3 w-100">
              <v-img :src="logoImage" class="m-2" style="height: 8vh"></v-img>

              <v-card-title
                class="h4 font-weight-bold mb-1 align-items-center"
                >{{ $t("legenda_136") }}</v-card-title
              >

              <v-card-text class="mt-0">
                <v-item-group class="my-2">
                  <alert
                    :variant="alertVariant"
                    :messageList="alertMessages"
                    v-if="showAlert"
                  ></alert>
                </v-item-group>

                <v-item-group>
                  <v-text-field
                    type="email"
                    @change="updateUsername($event)"
                    :label="$t('legenda_140')"
                    :placeholder="$t('legenda_141')"
                    prepend-inner-icon="mdi-email"
                    variant="outlined"
                    required
                    autocomplete="on"
                  ></v-text-field>
                </v-item-group>

                <v-item-group>
                  <v-text-field
                    type="password"
                    v-model="user.password"
                    :placeholder="$t('legenda_099')"
                    :label="$t('legenda_100')"
                    prepend-inner-icon="mdi-lock"
                    variant="outlined"
                    required
                  ></v-text-field>
                </v-item-group>

                <v-item-group style="text-align: right">
                  <router-link
                    class="text-typo-secondary"
                    to="/forgot_password"
                    >{{ $t("legenda_132") }}</router-link
                  >
                </v-item-group>

                <v-item-group>
                  <v-btn
                    class="mt-3 mb-3 text-typo-surface"
                    style="width: 100%"
                    color="button-primary"
                    type="submit"
                    >{{ $t("legenda_095") }}</v-btn
                  >
                </v-item-group>
              </v-card-text>

              <v-card-actions>
                <div class="p-2">
                  {{ $t("legenda_137") }}
                  <router-link
                    class="text-typo-secondary"
                    to="/subscriptions"
                    >{{ $t("legenda_138") }}</router-link
                  >
                </div>
              </v-card-actions>
            </v-card>
          </form>
        </div>
      </div>
      <div class="mt-5">
        <metis_footer
          :fontColor="'text-typo-secondary'"
          :customColor="'white'"
        />
      </div>
    </v-col>

    <v-col
      class="full-img text-light d-flex flex-column"
      style="justify-content: space-evenly"
    >
      <div class="d-flex flex-column text-typo-primary" style="margin-left: 5%">
        <br />
        <br />
        <div class="text-h3">{{ $t("legenda_155") }}</div>
        <br />
        <h4>
          {{ $t("legenda_156", 2) }} <br />
          {{ $t("legenda_157") }} <br />
          {{ $t("legenda_158") }}
        </h4>
      </div>
      <div class="d-flex flex-column">
        <div class="legenda-flex-column video-style">
          <video
            autoplay
            muted
            loop
            class="video-layout"
            :src="require('../../../assets/images/legenda_usage_video.mp4')"
            type="video/mp4"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AlertComponent from "@/components/AlertComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { mapActions, mapGetters } from "vuex";
import logoImg from "../../../assets/images/legenda-logo-white.svg";
export default {
  name: "LoginView",

  components: {
    alert: AlertComponent,
    metis_footer: FooterComponent,
  },
  computed: {
    ...mapGetters({ olympiaLexView: "olympiaLexView" }),
    customerPortal() {
      // encode email: foo.bar_baz+10@bar.com -> foo.bar_baz%2B10%40bar.com
      const encodedUsername = encodeURIComponent(this.user.username);
      return `${process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${encodedUsername}`;
    },
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      logoImage: logoImg,
      alertVariant: "warning",
      alertMessages: [{ text: "legenda_080" }],
      showAlert: false,
    };
  },
  methods: {
    ...mapActions(["logIn"]),
    /**
     *
     * @param {*} event
     */
    updateUsername(event) {
      this.user.username = event.target.value;
    },

    async submit() {
      try {
        const response = await this.logIn(this.user);
        if (response) {
          const name = this.olympiaLexView
            ? "olympialexDashboard"
            : "Dashboard";
          this.$router.push({ name: name });
        } else {
          this.showAlert = true;

          this.alertMessages = [
            { text: "legenda_080" },
            {
              text: "legenda_084",

              links: [
                {
                  href: "/subscriptions",
                  placeholder: "legenda_085",
                  openInTab: false,
                  customClass: "text-typo-blue",
                },
              ],
            },
            {
              text: "legenda_081",
              links: [
                {
                  href: this.customerPortal,
                  placeholder: "legenda_082",
                  openInTab: true,
                  customClass: "text-typo-blue",
                },
                {
                  href: "mailto:info@metisai.it",
                  placeholder: "legenda_028",
                  openInTab: false,
                  customClass: "text-typo-blue",
                },
              ],
            },
          ];
        }
      } catch (error) {
        this.showAlert = true;

        this.alertMessages = [{ text: "legenda_080" }];
      }
    },
  },
};
</script>

<style>
.full-img {
  background-image: url("~@/assets/images/login_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.video-style {
  width: 40vw;
  margin-left: auto;
  margin-right: 1%;
  border-color: #efebe2;
  border-bottom-width: 4px;
  border-top-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-style: solid;
  border-radius: 16px;
  overflow: hidden !important;
}
.video-layout {
  cursor: auto;
  width: 100%;
  height: auto;
  border-radius: 16px;
  object-fit: contain;
  background-color: transparent;
  object-position: 50% 50%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
