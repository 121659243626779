/**
 *
 * @param {*} textToFormat
 * @param {*} links
 * @param {*} customClass
 * @returns
 */
function formatLink(link) {
  // build link
  const target = link.openInTab ? "_blank" : "_self";
  const classes = link.customClass ? link.customClass : "text-typo-primary";
  link.tag = `<a class="${classes}" target=${target} href=${link.href}>${link.text}</a>`;

  return link;
}

export { formatLink };
