<template>
  <v-container fluid class="h-100 m-0 p-0">
    <v-row no-gutters class="h-100 bg-page-primary overflow-hidden">
      <v-col
        cols="12"
        xl="8"
        lg="9"
        md="10"
        sm="12"
        xs="12"
        class="legenda-flex-column py-1 mx-auto px-4"
        style="align-items: center"
      >
        <searchInput
          style="flex: none !important; width: -webkit-fill-available"
          :width="800"
          :color="'input-primary'"
          :density="'comfortable'"
          v-model="searchQuery"
          :searchQuery="searchQuery"
          :searchScope="searchScopeReactive"
          :searchInProgress="loading"
          @doSearch="doSearch"
        ></searchInput>

        <div class="h-100 w-100 overflow-hidden flex-grow-1">
          <documentViewer
            v-model="pdfBinary"
            class="h-100 w-100"
            :fileName="fileName"
            :pdfBinary="pdfBinary"
          />
        </div>
      </v-col>

      <v-snackbar
        class="elevation-0"
        v-model="showStatusAlertComputed"
        :location="'top'"
        :multi-line="true"
        color="primary-lighten-3"
        :timeout="-1"
      >
        {{ $t(alertMessage) }}
        <template v-slot:actions>
          <v-btn class="mx-1" flat @click="userClicked = true">
            {{ $t("legenda_040") }}
          </v-btn>
        </template>
      </v-snackbar>
      <searchErrorModalComp
        :name="fileName"
        :folderId="folderToSearchComputed"
        :issueType="'documentSearch'"
        :issueInfo="{ search_query: searchQuery }"
        v-model="searchErrorModal"
        v-on:issueSubmitted="searchErrorModal = false"
      />

      <errorModalCmp
        :title="'legenda_030'"
        :content="errorModalText"
        :okButtonLabel="'legenda_040'"
        :cancelButtonLabel="'legenda_039'"
        v-model="errorModal"
        v-on:ok="errorModal = false"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DocumentViewer from "../../components/document/DocumentViewerComponent.vue";
import SearchErrorModal from "../../components/search/SearchErrorModal.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import SearchInputComponent from "../../components/search/SearchInputComponent.vue";

export default {
  name: "DocumentExplorer",
  components: {
    documentViewer: DocumentViewer,
    searchErrorModalComp: SearchErrorModal,
    errorModalCmp: ModalComponent,
    searchInput: SearchInputComponent,
  },
  props: {
    folder_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      userClicked: false,
      searchScopeReactive: null,
      docToShow: null,
      fileName: '',
      index: 0,
      showStatusAlert: false,
      statusAlert: null,
      errorModal: false,
      errorModalText: "legenda_033",
      alertMessage: "legenda_041",
      searchErrorModal: false,
      page: 1,
      document_id: '',
    };
  },

  computed: {
    ...mapGetters({
      pdfBinary: "statePdfBinary",
      searchQuery: "stateSearchQuery",
      searchResults: "stateSearchResults",
      currentFolderId: "stateCurrentFolderId",
      pdfs: "statePdfs",
      olympiaLexView: "olympiaLexView",
      llmSearchOption: "llmSearchOption",
      searchScope: "stateSearchScope",
      searchMode: "stateSearchMode",
      tree: "stateTree",
      progress: "stateProgress",
    }),
    /**
     *
     */
    showStatusAlertComputed() {
      return this.showStatusAlert && !this.userClicked;
    },
    /**
     *
     */
    folderToSearchComputed() {
      return this.searchScope[0].type == "folder"
        ? this.searchScope[0].itemId
        : this.searchScope[0].parentId;
    },
  },
  watch: {
    /**
     *
     */
    progress(newValue) {
      if (this.searchScope[0].type === document) {
        const documentInProgress = newValue.find(
          (element) => element.document_id === this.searchScope[0].itemId
        );
        if (documentInProgress) {
          this.searchScope[0].status = documentInProgress.status;
        }
        this.$store.commit("setSearchScope", this.searchScope[0]);
        this.setStatusAlert(this.searchScope[0]);
      }
    },
  },
  methods: {
    ...mapActions(["search", "queryLLM", "getPdf"]),
    /**
     *
     */
    async doSearch(searchQueryObject) {
      this.$store.commit("setSearchQueryObject", searchQueryObject);
      await this.submitSearch();
    },
    /**
     *
     */
    checkScopeStatus(scope) {
      this.alertMessage = "legenda_041";
      return scope.status !== 200;
    },
    /**
     *
     */
    async submitSearch() {
      if (!this.searchQuery) return;
      this.$store.commit("setSearchResults", null);
      if (this.searchScope[0].type === "folder") {
        this.$store.commit("setPdfBinary", new ArrayBuffer(0));
      }
      // search
      this.loading = true;
      try {
        const documentId =
          this.searchScope[0].type === "document" ? this.searchScope[0].itemId : "";

        await this.search({
          parentFolderId: this.folderToSearchComputed,
          documentId: documentId,
          key: this.searchQuery,
          llmSearch: this.llmSearchOption,
        });
        this.loading = false;

        if (this.llmSearchOption == true && this.searchMode === "semantic") {
          this.queryLLM({ searchQueryId: this.searchResults.search_query_id });
        }

        // go to search mode
        this.$router.push({
          name: "SearchResults",
          params: {
            search_query_id: this.searchResults.search_query_id,
          },
          state: {
            document_id: documentId,
            folder_id: this.folderToSearchComputed,
          },
        });
      } catch (error) {
        // error in search
        this.searchErrorModal = true;
        this.loading = false;
      }
    },

    /**
     *
     */
    setCurrentDocument(documentId, name) {
      this.document_id = documentId;
      this.fileName = name;
    },
    /**
     *
     */
    async loadPdfInExploreMode(document_id, name) {
      try {
        this.setCurrentDocument(document_id, name);
        // get document
        await this.getPdf(this.document_id);
      } catch (error) {
        // error in getting pdf
        this.errorModalText = this.olympiaLexView ? "legenda_034" : "legenda_033";
        this.errorModal = true;
      }
    },
    /**
     *
     */
    setStatusAlert(scope) {
      this.showStatusAlert = this.checkScopeStatus(scope);
    },
    /**
     *
     */
    setScopeForDocument() {
      // before we go, reset the scope to the folderToSearch if the the new scope is still a document
      if (this.searchScope.length > 0 && this.searchScope[0].type == "document") {
        const newScope = this.$filters.deepSearch(
          this.tree,
          this.folderToSearchComputed,
          "itemId",
          "children" //where to look for
        );
        this.$store.commit("setSearchScope", newScope);
      }
    },
  },
  /**
   *
   */
  async mounted() {
    this.searchScopeReactive = this.searchScope;
    //check if it is searchable
    this.setStatusAlert(this.searchScope[0]);
    // await this.loadPdfInExploreMode(window.history.state.document_id);
    await this.loadPdfInExploreMode(window.history.state.document_id, window.history.state.name);
  },
  /**
   *
   */
  beforeRouteLeave(to, from, next) {
    // files are kept in local storage with their initial config (scale, page etc)
    // remove after exiting pdf explorer to avoid overriding existing configs
    // a file that has been opened normally and after search is recognised as the same from the pdf viewer
    // so we need to override its scale; too much, therefore, clear storage and start new depending on launch mode (normal /highlight)
    localStorage.removeItem("pdfjs.history");
    this.$store.commit("setPdfBinary", new ArrayBuffer(0)); //take out
    if (to.name !== "SearchResults") {
      this.setScopeForDocument();
    }
    next();
  },
};
</script>
