import axiosWrapper from "../../plugins/axios/axiosWrapper";

const actions = {
  // eslint-disable-next-line no-empty-pattern
  async checkoutComplete({}, checkoutSessionId) {
    return await axiosWrapper.get(
      `/subscription/checkout_complete/:checkoutSessionId`,
      { checkoutSessionId: checkoutSessionId }
    );
  },
};

export default {
  actions,
};
