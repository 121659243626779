import "vuetify/styles";
import "../../assets/styles/global.css";
import { createVuetify } from "vuetify";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { getThemes } from "./paletteUtils.js";

const defaultTheme = localStorage.getItem("appTheme") || "legendaTheme";

const vuetifyInstance = createVuetify({
  theme: {
    defaultTheme: defaultTheme,
    themes: getThemes(),
    options: {
      customProperties: true,
    },
    typography: {
      font: {
        family: {
          base: "Satoshi, sans-serif",
          heading: "Satoshi, sans-serif",
        },
      },
    },
  },
  options: {
    customProperties: true, // Enable CSS variables for custom themes
  },
  components,
  directives,
});

export { vuetifyInstance };
