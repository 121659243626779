<template>
  <v-row class="bg-page-secondary h-100 m-auto">
    <v-col
      cols="12"
      class="p-0 d-flex align-items-center flex-column justify-space-evenly"
    >
      <div class="m-3 w-50">
        <router-link to="/login">
          <v-img
            style="height: 8vh"
            :src="require('../../assets/images/legenda-logo.svg')"
          ></v-img>
        </router-link>
      </div>
      <div class="contact-card">
        <v-card class="p-4 h-100">
          <v-card-title class="h4 m-auto">{{ $t("legenda_028") }} </v-card-title>
          <v-card-text class="p-4" style="text-align: justify">
            <span>{{ $t("legenda_140") }}: </span>
            <a href="mailto: info@metisai.it">info@metisai.it</a>
          </v-card-text>
        </v-card>
      </div>
      <div class="mt-5">
        <metis_footer :fontColor="'text-typo-primary'" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  name: "ContactView",
  components: {
    metis_footer: FooterComponent,
  },
};
</script>
<style>
.contact-card {
  height: 40%;
  padding: 2%;
  width: 80%;
}
</style>
