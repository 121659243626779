import inspectSearchService from "./inspectSearchService";

class BaseInspectSearchConfiguration {
  /**
   *
   * @param {*} pdfs
   */
  checkPdfStatus(pdfs) {
    if (!pdfs) return false;

    // all none searchable pdfs
    return pdfs.some((document) => document.status !== 200);
  }
  /**
   *
   * @param {*} folders
   * @returns
   */
  checkFolderStatus(folders) {
    if (!folders) return false;
    const nonSearchable = folders.some(
      (folder) => folder.status === 300 || folder.status === 100
    );
    return nonSearchable;
  }
  /**
   *
   */
  createPaylod() {
    this._WARNING("createPayload(items, documentId)");
  }
  /**
   *
   * @returns
   */
  setShowStatusAlert() {
    this._WARNING("setShowStatusAlert()");
  }
}

class FolderInspectSearchConfigruation extends BaseInspectSearchConfiguration {
  /** @inheritdoc */
  createPayload(searchResults, fileName, keySentencesLoaded) {
    return inspectSearchService.folderSearchPayload(
      searchResults,
      fileName,
      keySentencesLoaded
    );
  }
  /** @inheritdoc */
  setShowStatusAlert(scope) {
    const documents = scope.children.filter((element) => {
      return element.type === "document";
    });
    const folders = scope.children.filter((element) => {
      return element.type === "folder";
    });
    return super.checkPdfStatus(documents) || super.checkFolderStatus(folders);
  }
  /**
   *
   * @returns
   */
  statusAlertMessage() {
    return "legenda_042";
  }
}

class DocumentInspectSearchConfiguration extends BaseInspectSearchConfiguration {
  /** @inheritdoc */
  createPayload(searchResults) {
    return inspectSearchService.documentSearchPayload(searchResults);
  }
  /** @inheritdoc */
  setShowStatusAlert(document) {
    return document.status !== 200;
  }
  /**
   *
   * @returns
   */
  statusAlertMessage() {
    return "legenda_041";
  }
}

class ConfigurationFactory {}

// default instance
ConfigurationFactory.prototype.configClass = FolderInspectSearchConfigruation;

// class constructor
ConfigurationFactory.prototype.createConfig = function (documentId) {
  switch (documentId) {
    case null:
    case "":
      this.configClass = FolderInspectSearchConfigruation;
      break;
    default:
      this.configClass = DocumentInspectSearchConfiguration;
      break;
  }
  return new this.configClass(documentId);
};
const configFactory = new ConfigurationFactory();
export default configFactory;
