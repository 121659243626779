<template>
  <div class="metrics-wrapper">
    <div class="metrics-intro mt-4 pt-4 text-white">
      <div class="text-h34 p-2 m-2 font-weight-bold">
        {{ $t("legenda_174") }}
      </div>
      <div class="text-h6 p-2 m-2">{{ $t("legenda_175") }}</div>
    </div>
    <div>
      <v-row class="metrics-layout">
        <v-col
          cols="12"
          md="3"
          sm="5"
          xs="4"
          v-for="metric in metrics"
          :key="metric.description"
        >
          <v-card
            class="rounded-b-0 rounded-t-xl legenda-flex-column metric-card-layout"
            variant="outlined"
          >
            <template v-slot:title>
              <v-responsive class="text-h4 pt-4 pb-0 font-weight-bold">
                {{ $t(metric.description) }}
              </v-responsive>
            </template>
            <template v-slot:text>
              <div class="font-weight-light p-1 metric-text-style">
                {{ $t(metric.text) }}
              </div>
            </template>
            <div class="metric-emphasize"></div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetricsComponent",
  data() {
    return {
      metrics: [
        {
          description: "legenda_176",
          text: "legenda_177",
        },
        { description: "legenda_178", text: "legenda_179" },
        { description: "legenda_180", text: "legenda_181" },
        { description: "legenda_182", text: "legenda_183" },
      ],
    };
  },
};
</script>

<style>
.metrics-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #292929;
  width: 100%;
}

.metric-card-layout {
  background-color: white !important;
  height: 170px;
}

.metrics-intro {
  max-width: 600px;
}

.metrics-layout {
  padding: 3rem;
  justify-content: center;
}

.metric-text-style {
  font-size: large;
}

.metric-emphasize {
  width: 100%;
  height: 10px;
  background-color: #f1ff82;
}
</style>
