<template>
  <v-sheet class="legenda-flex-column text-contrast p-1 m-1">
    <div id="tableHead" class="legenda-flex-row align-items-center table-head">
      <div id="breadcrumbs">
        <breadcrumbs
          v-if="breadcrumbsProperties"
          :breadcrumbsProps="breadcrumbsProperties.breadcrumbsProps"
          :dividerIcon="breadcrumbsProperties.dividerIcon"
        ></breadcrumbs>
      </div>
      <div
        id="controlButtons"
        class="control-buttons"
        v-if="controlButtons && controlButtons.length > 0"
      >
        <v-btn
          v-for="button in controlButtons"
          :key="button.descr"
          :title="$t(button.title)"
          :prepend-icon="button.icon"
          :disabled="!button.enabled"
          :hidden="button.hideButton || false"
          :class="['m-1', button.class]"
          variant="outlined"
          @click="executeButtonAction(button.descr)"
        >
          {{ $t(button.label) }}
        </v-btn>
      </div>
    </div>
    <v-sheet
      class="py-1 px-4 legenda-flex-column"
      id="tableData"
      elevation="0"
      v-if="content && content.length > 0"
    >
      <v-table
        class="table-height text-black"
        :class="customTableStyle"
        theme="light"
        :density="density"
        :height="calcHeight"
        fixed-header
        hover
      >
        <thead :hidden="hideHeader">
          <tr>
            <th
              :width="column.width || 'auto'"
              v-for="column in filteredHeader"
              :key="column.descr"
              color="checkbox-primary"
              :class="{
                'text-center': column.center,
                'small-width': column.multiselect,
              }"
            >
              {{ column.name ? $t(column.name) : null }}
              <!--a column can be a multiselect column-->
              <v-checkbox-btn
                v-if="column.multiselect"
                v-model="selectAll"
                @change="select"
                :class="{
                  'checkbox-style': true,
                  'small-width': column.multiselect,
                }"
                hide-details="true"
              ></v-checkbox-btn>
              <!--a column can be sortable, actively from the user or from the code-->
              <v-icon
                v-if="column.sort && column.activeSort"
                color="icon-primary"
                size="xs"
                @click="toggleSort(column)"
                >{{ sortIcon(column.sort) }}
              </v-icon>
            </th>
          </tr>
        </thead>
        <tbody style="color: black">
          <tr v-for="item in content" :key="item.name">
            <td
              v-for="column in filteredHeader"
              :key="column.descr"
              :style="[
                tableConfig.clickRow && column.descr !== 'action'
                  ? 'cursor:pointer'
                  : 'cursor:default',
              ]"
            >
              <!--a column can be a multiselect column-->
              <v-sheet
                v-if="column.multiselect"
                style="background: transparent"
              >
                <v-checkbox-btn
                  :class="{
                    'checkbox-style': true,
                    'small-width': column.multiselect,
                  }"
                  v-model="item.isChecked"
                  color="checkbox-primary"
                  hide-details="true"
                  @change="itemSelected(item, $event)"
                ></v-checkbox-btn>
              </v-sheet>
              <!-- a column can contain formated value with optional prepending icon and row clik emitter-->
              <v-sheet
                v-if="column.descr !== 'action' && column.descr !== 'chips'"
                class="row-style legenda-flex-row align-items-center"
                @click="rowClicked(item)"
              >
                <v-icon
                  class="mx-2"
                  :class="item.customStyle"
                  v-if="column.useIcon"
                  >{{ item.icon }}</v-icon
                >
                {{ formattedValue(item[column.descr], column.type) }}

                <div v-if="column.hasInlineActions">
                  <div
                    v-for="inlineAction in item.inlineActions"
                    :key="inlineAction"
                  >
                    <v-btn
                      class="d-flex ml-2"
                      size="x-small"
                      :title="$t(inlineAction.title)"
                      :icon="inlineAction.icon"
                      color="button-contrast"
                      @click="executeAction(inlineAction.action, item)"
                      variant="text"
                      density="compact"
                      v-if="inlineAction.showActionIcon"
                    >
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
              <!--a column can contain chips-->
              <v-sheet
                v-if="column.descr === 'chips'"
                class="row-style d-flex flex-row justify-content-center"
              >
                <v-chip
                  v-for="chip in item.chips"
                  :key="chip.name"
                  label
                  :color="chip.color"
                  :class="chip.class"
                  :append-icon="chip.icon"
                  >{{ $t(chip.name) }}
                  <v-tooltip
                    :text="$t(chip.text)"
                    activator="parent"
                    :location="chip.location"
                  >
                  </v-tooltip>
                </v-chip>
              </v-sheet>
              <!--a column can contain actions-->
              <v-sheet
                v-if="column.descr === 'action'"
                class="row-style d-flex flex-row justify-content-center"
              >
                <v-btn
                  v-for="action in item.actions"
                  class="d-flex ml-2"
                  size="x-small"
                  variant="flat"
                  :title="$t(actionTitle(action.actionName))"
                  :key="action"
                  :icon="actionIcon(action.actionName)"
                  color="button-primary"
                  :disabled="!action.actionEnabled"
                  @click="executeAction(action.actionName, item)"
                >
                </v-btn>
              </v-sheet>
              <!--a column can be a dynamic component-->
              <v-sheet v-if="column.isDynamic">
                <keep-alive>
                  <component
                    :is="loadDynamicComponent(column.component, item)"
                    v-bind="item.componentProps"
                  ></component>
                </keep-alive>
              </v-sheet>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-sheet>
  </v-sheet>
</template>

<script>
import _ from "lodash";
import { defineAsyncComponent } from "vue";
import BreadcrumbsComponent from "../components/breadcrumbs/BreadcrumbsComponent.vue";
export default {
  name: "TableComponent",
  components: {
    breadcrumbs: BreadcrumbsComponent,
  },
  computed: {
    /**
     *
     */
    calcHeight() {
      // row height: 36
      //header height: 40
      const rowHeight = this.density === "compact" ? 36 : 52;
      let headerHeight = 0;
      if (!this.hideHeader) {
        headerHeight = this.density === "compact" ? 40 : 56.99;
      }

      if (this.content.length * rowHeight + headerHeight > this.mxHeight) {
        //return max height
        return this.mxHeight;
      } else {
        //return height according to how many elements we have
        return "auto";
      }
    },
    filteredHeader() {
      if (this.tableConfig?.selectMode == false && this.header[0].multiselect) {
        return this.header.slice(1);
      }
      return this.header;
    },
  },
  props: {
    tableConfig: null,
    customTableStyle: {},
    //max height in vh
    mxHeight: { type: Number, default: 0 },
    density: {
      type: String,
      default: "compact",
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },

    breadcrumbsProps: null,
  },

  data() {
    return {
      header: null,
      content: null,
      actions: null,
      controlButtons: null,
      selectAll: false,
      breadcrumbsProperties: null,
    };
  },
  methods: {
    /**
     *
     */
    select() {
      for (let i in this.content) {
        this.content[i].isChecked = this.selectAll;
      }

      const itemsChecked = this.content.filter((el) => {
        return el.isChecked === true;
      });

      this.controlButtons.forEach((button) => {
        if (button.descr === "deleteItems") {
          button.enabled = itemsChecked.length > 0;
        }
      });

      this.$emit("allSelected", itemsChecked);
    },
    /**
     *
     */
    itemSelected(item, event) {
      const elementsChecked = this.content.filter((el) => {
        return el.isChecked == true;
      });

      this.selectAll = this.content.length === elementsChecked.length;
      item.isChecked = event.target.checked;

      this.controlButtons.forEach((button) => {
        if (button.descr === "deleteItems") {
          button.enabled = elementsChecked.length > 0;
        }
      });
      this.$emit("allSelected", elementsChecked);
    },
    /**
     *
     * @param {*} action
     */
    executeButtonAction(action) {
      this.$emit(action);
    },
    /**
     *
     * @param {*} action
     * @param {*} element
     */
    executeAction(action, element) {
      this.$emit(action, element);
    },
    /**
     *
     * @param {*} action
     */
    actionTitle(action) {
      const act = this.actions.filter((act) => {
        if (act.action === action) return act;
      });
      return act[0].title;
    },
    /**
     *
     * @param {*} action
     */
    actionIcon(action) {
      const act = this.actions.filter((act) => {
        if (act.action === action) {
          return act;
        }
      });
      return act[0].icon;
    },
    /**
     *
     * @param {*} item
     */
    rowClicked(item) {
      if (this.tableConfig.clickRow) {
        this.$emit("rowClicked", item);
      }
    },
    /**
     *
     * @param {*} property
     */
    dynamicSort(column) {
      const property = column.sortBy;

      let sortOrder = 1;

      if (column.sort === "desc") {
        sortOrder = -1;
      }
      this.content.sort(function (a, b) {
        let result = 1;
        switch (column.type) {
          // depending on the column type, different calculations
          case "datetime":
            result = Date.parse(a[property]) - Date.parse(b[property]);
            break;
          case "number":
            result = a[property] - b[property];
            break;
          case "string":
            // use this for case insensitive comparison
            result = a[property].localeCompare(b[property], undefined, {
              sensitivity: "base",
            });
            break;
        }
        // revert the order according to the sorting direction
        return result * sortOrder;
      });
      return this.content;
    },
    /**
     *
     * @param {*} column
     */
    toggleSort(column) {
      if (column.sort == "desc") {
        column.sort = "asc";
      } else {
        column.sort = "desc";
      }
      // reset all other icons to avoid sort confusion to the user
      this.header.forEach((element) => {
        if (element.name !== column.name) {
          element.sort = "default";
        }
      });

      this.dynamicSort(column);
    },
    /**
     *
     * @param {*} direction
     */
    sortIcon(direction) {
      switch (direction) {
        case "asc":
          return "mdi-sort-ascending";
        case "desc":
          return "mdi-sort-descending";
        default:
          return "mdi-sort";
      }
    },
    /**
     *
     * @param {*} value
     * @param {*} columnType
     */
    formattedValue(value, columnType) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      switch (columnType) {
        case "datetime":
          return value
            ? new Date(value).toLocaleDateString("en-GB", options)
            : "";
        default:
          return value;
      }
    },
    /**
     *
     */
    updateSelectedItems() {
      if (this.content.length === 0) this.selectAll = false;
    },
    /**
     *
     */
    loadDynamicComponent(component, item) {
      if (component.name && item != null) {
        return defineAsyncComponent(() =>
          import(`@/${component.path}/${component.name}.vue`)
        );
      }
    },
  },
  watch: {
    tableConfig: {
      handler(newConfig) {
        this.content = _.cloneDeep(newConfig.content);

        if (this.controlButtons) {
          this.controlButtons.forEach((button) => {
            if (button.descr === "deleteItems") {
              button.hideButton = this.content.length === 0 || (!this.tableConfig.showDeleteAll
              || (this.tableConfig.selectMode != undefined && this.tableConfig?.selectMode == false));
              
              const itemsChecked = this.content.filter((el) => {
                return el.isChecked === true;
              });
              button.enabled = itemsChecked.length > 0;
            }
            if (button.descr === "selectMode") {
              button.class = this.tableConfig.selectMode == true ? "bg-button-primary" : ""
            }
          });
        }

        this.updateSelectedItems();
        if (this.tableConfig.autoSort) {
          this.header.forEach((column) => {
            if (column.sort) {
              this.dynamicSort(column);
            }
          });
        }
      },
      deep: true,
    },

    breadcrumbsProps: {
      handler(newConfig) {
        this.breadcrumbsProperties = _.cloneDeep(newConfig);
      },

      deep: true,
    },
  },
  created() {
    if (this.tableConfig) {
      this.breadcrumbsProperties = _.cloneDeep(this.breadcrumbsProps);
      this.header = _.cloneDeep(this.tableConfig.header);
      this.content = _.cloneDeep(this.tableConfig.content);
      this.actions = _.cloneDeep(this.tableConfig.actions);
      this.controlButtons = _.cloneDeep(this.tableConfig.controlButtons);

      this.controlButtons.push({
        descr: "deleteItems",
        label: "legenda_126",
        icon: "mdi-delete-outline",
        title: "legenda_131",
        enabled: false,
        hideButton: this.content.length === 0 || (!this.tableConfig.showDeleteAll
          || (this.tableConfig.selectMode != undefined && this.tableConfig?.selectMode == false)),
      });

      if (this.tableConfig.selectMode != undefined) {
        this.controlButtons.push({
          descr: "selectMode",
          label: "legenda_204",
          icon: "mdi-checkbox-marked-outline",
          title: "legenda_204",
          enabled: true,
          hideButton: false,
          class: this.tableConfig.selectMode == true ? "bg-button-primary" : ""
        });
      }
    }
  },
};
</script>

<style>
.small-width {
  padding-left: 6px !important;
  padding-right: 6px !important;
  width: 50px !important;
}
.row-style {
  width: 100;
  background-color: transparent !important;
}
.control-buttons {
  display: flex;
  flex-wrap: wrap;
  min-width: fit-content;
  max-width: min-content;
  align-content: center;
}
.table-height {
  max-height: 100%;
}
.table-head {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 460px) {
  .control-buttons {
    flex-direction: column;
    margin-left: auto;
  }
}
</style>
