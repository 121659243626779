<template>
  <section
    class="w-100 pt-4 pb-1 d-flex flex-column flex-wrap align-items-center"
  >
    <intro></intro>
    <videoCmp></videoCmp>
    <features></features>
    <metrics></metrics>
    <quotes></quotes>
    <footerCmp></footerCmp>
  </section>
</template>

<script>
import FooterComponent from "../../components/ui/FooterComponent.vue";
import IntroComponent from "./components/demo/IntroComponent.vue";
import VideoComponent from "./components/demo/VideoComponent.vue";
import FeaturesComponent from "./components/features/FeaturesComponent.vue";
import MetricsComponent from "./components/metrics/MetricsComponent.vue";
import QuotesComponent from "./components/quote/QuotesComponent.vue";
import { languageService } from "../../services/languageService";
import { i18nInstance } from "../../translations/i18n";
export default {
  name: "LandingPageView",
  props: {
    language: {
      type: String,
      default: null,
    },
  },
  components: {
    intro: IntroComponent,
    videoCmp: VideoComponent,
    features: FeaturesComponent,
    metrics: MetricsComponent,
    quotes: QuotesComponent,
    footerCmp: FooterComponent,
  },
  async created() {
    //if there is a language passed as a param, load the translations
    const language = this.$route.params.language;

    if (language) {
      await languageService.setLocale(language, i18nInstance, this.$root.$i18n);
    }
  },
};
</script>
<style></style>
