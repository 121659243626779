<template>
  <v-dialog
    v-model="dialog"
    contained
    location="top"
    :persistent="persistent"
    hide-overlay
  >
    <div class="m-auto bg-modal-primary text-typo-primary">
      <v-card class="w-auto h-auto m-auto rounded-0" color="transparent">
        <template v-slot:title>
          <div class="d-flex flex-row justify-space-between p-1">
            <h5>{{ $t(title) }}</h5>
            <v-btn
              v-if="showCloseButton"
              class="my-auto p-auto"
              icon="mdi-close"
              color="primary"
              size="x-small"
              @click="onCloseModal"
            ></v-btn>
          </div>
        </template>

        <v-card-text>
          <p>{{ $t(content) }}</p>
        </v-card-text>
        <v-card-actions class="justify-content-center m-2">
          <v-btn
            v-if="isConfirmationModal"
            color="button-secondary"
            class="mr-2 text-secondary"
            variant="plain"
            @click="onCancel"
          >
            {{ $t(cancelButtonLabel) }}
          </v-btn>
          <v-btn color="button-primary" variant="flat" @click="onOk">
            {{ $t(okButtonLabel) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "info",
    },
    okButtonLabel: {
      type: String,
      default: "legenda_040",
    },
    cancelButtonLabel: {
      type: String,
      default: "legenda_039",
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: "color: primary;",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isConfirmationModal() {
      return this.type === "confirmation";
    },
    closeDialog() {
      return !this.dialog;
    },
  },
  methods: {
    onOk() {
      this.$emit("ok");
    },
    onCancel() {
      this.$emit("cancel");
    },

    onCloseModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
