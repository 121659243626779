<template>
  <v-row class="bg-page-secondary h-100 m-auto">
    <v-col
      cols="12"
      xs="9"
      sm="9"
      md="5"
      lg="4"
      x="4"
      class="m-auto d-flex flex-column"
      style="height: 100%; justify-content: space-evenly"
    >
      <div class="d-flex flex-column">
        <v-card flat class="pl-3 pr-3" color="transparent">
          <v-img
            class="my-5"
            :src="require('../assets/images/legenda-logo.svg')"
            style="height: 8vh; width: auto"
          ></v-img>

          <alert
            v-if="alertVariant"
            :variant="alertVariant"
            :messageList="alertMessages"
          ></alert>
          <v-skeleton-loader
            v-if="!alertVariant"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-card>
      </div>

      <div class="p-2">
        <metis_footer :fontColor="'text-typo-primary'"></metis_footer>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FooterComponent from "../components/FooterComponent.vue";
import AlertComponent from "../components/AlertComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "CheckoutView",

  props: ["sessionId"],

  components: {
    metis_footer: FooterComponent,
    alert: AlertComponent,
  },
  computed: {
    customerPortal() {
      return process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL;
    },

    prefilledEmail: {
      get: function () {
        return this.username;
      },
      set: function (value) {
        this.username = value;
      },
    },
  },
  methods: {
    ...mapActions(["checkoutComplete"]),
  },
  data() {
    return {
      username: "",
      alertVariant: null,
      alertMessages: [],
    };
  },

  async created() {
    // see what happened with this session
    try {
      let response = null;
      if (this.sessionId !== "mockValidSessionId") {
        response = await this.checkoutComplete(this.sessionId);
      }
      this.alertVariant = "success";
      this.alertMessages =
        response.status == 201
          ? [{ text: "legenda_088" }]
          : [{ text: "legenda_219" }];
    } catch (error) {
      this.alertVariant = "warning";

      if (error.response && error.response.status === 403) {
        this.alertMessages = [
          {
            text: "legenda_089",
            links: [
              {
                href: "mailto:info@metisai.it",
                placeholder: this.$t("legenda_087"),
                openInTab: false,
              },
              {
                href: this.customerPortal,
                placeholder: this.$t("legenda_082"),
                openInTab: true,
              },
            ],
          },
        ];
      } else {
        this.alertMessages = [
          {
            text: "legenda_090",
            links: [
              {
                href: "mailto:info@metisai.it",
                placeholder: this.$t("legenda_087"),
                openInTab: false,
              },
            ],
          },
        ];
      }
    }
  },
};
</script>
