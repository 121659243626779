<template>
  <v-row class="bg-page-secondary text-contrast h-100 m-auto">
    <v-col cols="12" class="d-flex flex-column justify-space-evenly mx-auto">
      <div class="m-3">
        <router-link to="/login">
          <v-img
            style="height: 8vh"
            :src="require('../../../assets/images/legenda-logo.svg')"
          ></v-img>
        </router-link>
      </div>

      <div class="mx-auto password-form-container">
        <password_form
          @submit.prevent="submit"
          :showAlert="showAlert"
          :alertMessages="alertMessages"
          :buttonLabel="$t('legenda_144')"
          :validForm="isPasswordValid"
          v-on:passwordChanged="(value) => setPassword(value)"
        ></password_form>
      </div>

      <div class="mt-3">
        <metis_footer></metis_footer>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PasswordFormComponent from "../../../components/credentials/PasswordFormComponent.vue";
import FooterComponent from "../../../components/FooterComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "UpdatePassword",

  props: ["token"],

  components: {
    password_form: PasswordFormComponent,
    metis_footer: FooterComponent,
  },

  data() {
    return {
      user: {
        username: "",
        password: "",
      },

      responseStatusCode: 0,

      alertMessages: [],
      showAlert: false,
      isPasswordValid: false,
    };
  },

  computed: {
    alertVariant() {
      return this.responseStatusCode == 200 ? "success" : "warning";
    },
  },

  methods: {
    ...mapActions(["updatePassword"]),

    /**
     *
     */
    async submit() {
      try {
        //confirm password update
        await this.updatePassword({ token: this.token, user: this.user });
        this.alertVariant = "success";
        this.alertMessages = [
          {
            text: "legenda_094",
            links: [
              {
                href: "/login",
                placeholder: this.$t("legenda_095"),
                openInTab: false,
              },
            ],
          },
        ];
        this.showAlert = true;
      } catch (error) {
        //notify the user
        this.alertVariant = "warning";
        if (error.response && error.response.status === 406) {
          this.alertMessages = [
            {
              text: "legenda_096",
              links: [
                {
                  href: "/forgot_password",
                  placeholder: "legenda_195",
                  openInTab: false,
                  customClass: "text-typo-blue",
                },
              ],
            },
          ];
        } else {
          this.alertMessages = [
            {
              text: "legenda_086",
              links: [
                {
                  href: "mailto:info@metisai.it",
                  placeholder: this.$t("legenda_087"),
                  openInTab: false,
                },
              ],
            },
          ];
        }

        this.showAlert = true;
      }
    },
    /**
     *
     * @param {*} passwordObject
     */
    setPassword(passwordObject) {
      this.isPasswordValid = passwordObject.isPasswordValid;
      this.user.password = passwordObject.password;
    },
  },
};
</script>

<style>
#app
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div
  > form
  > div:nth-child(3)
  > div
  > div.v-input__control
  > div
  > div.v-field__append-inner {
  color: green !important;
}

.password-form-container {
  width: fit-content;
}

@media (max-width: 808px) {
  .password-form-container {
    width: 100%;
  }
}
</style>
