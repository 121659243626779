<template>
  <div v-if="breadcrumbsToShow">
    <v-breadcrumbs
      v-model="breadList"
      density="compact"
      :class="{
        'm-0 p-1 font-weight-bold w-auto d-block': mode !== 'mini',
        'm-0 p-0 w-auto d-block': mode === 'mini',
        'opaque-text': disableLinks,
      }"
      :items="breadcrumbsToShow"
      :color="customColor"
    >
      <template v-slot:prepend>
        <div>
          <v-icon size="medium" v-if="prependIcon" :icon="prependIcon"></v-icon>
        </div>
      </template>

      <template v-slot:title="{ item }">
        <v-breadcrumbs-item
          class="p-0 m-0"
          :class="{ 'opaque-text': disableLinks }"
          :to="item.to"
          exact
          @click="breadcrumbItemClicked(item)"
          ><span style="word-break: break-all">
            {{ item.title }}
          </span>
        </v-breadcrumbs-item>
      </template>

      <template v-slot:divider>
        <span class="p-0 m-0" v-if="!dividerIcon">/</span>
        <v-icon v-if="dividerIcon">{{ dividerIcon }}</v-icon>
      </template>
    </v-breadcrumbs>

    <span></span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BreadcrumbsComponent",
  data() {
    return {
      breadCrumbsItems: [],
    };
  },
  props: {
    breadcrumbsProps: {},
    disableLinks: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "normal",
    },
    dividerIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    customColor: {
      type: String,
      default: "contrast",
    },
  },
  watch: {
    breadcrumbsProps: {
      handler(newList) {
        this.setBreadCrubms(newList);
      },
      deep: true,
    },
  },
  methods: {
    /**
     *
     * @param {*} breadList
     */
    setBreadcrumbsListCompact(breadList) {
      let breadcrumbsListShort = [];

      breadcrumbsListShort.push(breadList[0]);
      const name = this.olympiaLexView ? "olympialexContent" : "Content";
      let intermediateBreadcrumb = {
        title: "...",
        disabled: this.disableLinks,

        to: {
          name: name,
          params: {
            folder_id: breadList[breadList.length - 4].folder_id,
          },
        },
        folder_id: breadList[breadList.length - 4].folder_id,
      };
      breadcrumbsListShort.push(intermediateBreadcrumb);

      breadcrumbsListShort.push(breadList[breadList.length - 3]);

      breadcrumbsListShort.push(breadList[breadList.length - 2]);

      breadcrumbsListShort.push(breadList[breadList.length - 1]);

      this.breadcrumbsToShow = breadcrumbsListShort;
    },
    /**
     *
     * @param {*} newList
     */
    setBreadCrubms(newList) {
      if (newList && newList.length >= 6) {
        this.setBreadcrumbsListCompact(newList);
      } else if (newList) {
        this.breadcrumbsToShow = newList;
      }
    },
    getOpacity(item) {
      if (item.disabled) {
        return "opacity: 1.0 !important";
      }
    },
    breadcrumbItemClicked(item) {
      // we are changing the scope here
      const newScope = this.$filters.deepSearch(
        this.tree,
        item.folder_id,
        "itemId",
        "children" //where to look for
      );

      this.$store.commit("setSearchScope", newScope);
    },
  },

  computed: {
    ...mapGetters({
      olympiaLexView: "olympiaLexView",
      tree: "stateTree",
      searchScope: "stateSearchScope",
    }),
    breadList() {
      return this.breadcrumbsProps;
    },
    breadcrumbsToShow: {
      set(value) {
        this.breadCrumbsItems = value;
      },
      get() {
        return this.breadCrumbsItems;
      },
    },
  },
  created() {
    this.setBreadCrubms(this.breadcrumbsProps);
  },
};
</script>
<style>
.v-breadcrumbs-divider {
  padding: 0 1px !important;
}
.v-breadcrumbs-item {
  padding: 0 3px !important;
}

.opaque-text {
  opacity: 0.8 !important;
}
.v-breadcrumbs-item--disabled {
  opacity: 0.8 !important;
}
</style>
