<template>
  <div class="d-flex flex-column m-1">
    <h4
      v-if="
        keySentencesLoaded == 0 &&
        searchQuery &&
        searchQuery.trim().length > 0 &&
        !searchInProgress
      "
      class="text-danger mt-2"
    >
      {{ $t("legenda_019") }}
    </h4>

    <div>
      <!--llm skeleton is shown when an llm search is running and we are in the same search results as the long polling-->
      <div class="m-2 d-flex flex-column" v-if="showLlmSkeleton">
        <v-sheet
          style="width: 97%"
          elevation="0"
          class="mt-1 ml-auto p-3 rounded-te-xl rounded-ts-xl rounded-be-xl"
        >
          <v-skeleton-loader
            style="background-color: transparent; margin-left: auto"
            size="small"
            type="list-item-two-line, text"
          ></v-skeleton-loader>
        </v-sheet>
        <v-icon
          class="my-1 py-1"
          style="top: -10px; left: -3px; font-size: xx-large"
          >mdi-robot</v-icon
        >
      </div>
      <!--llm answer elkasp adapt this, not just any rag-->
      <div
        v-else-if="this.rag.items.length > 0"
        class="pb-2 d-flex flex-row align-items-end"
      >
        <v-icon
          class="my-1 py-1"
          style="top: 20px; left: 5p; font-size: xx-large"
          >mdi-robot</v-icon
        >
        <v-sheet
          elevation="0"
          class="m-1 p-3 rounded-te-xl rounded-ts-xl rounded-be-xl"
        >
          <div class="py-2 font-italic">
            <span v-for="i in this.rag.items" :key="i">
              {{ i.answer }}
              <span
                v-for="search_result_id in i.search_result_ids"
                :key="search_result_id"
                @click="ragAnswerClicked(search_result_id)"
                style="cursor: pointer; color: blue"
              >
                [{{
                  searchResultsComputed.items.findIndex(
                    (x) => x.search_result_id == search_result_id
                  ) + 1
                }}]
              </span>
            </span>
          </div>
          <p class="llm-disclaimer text-disabled font-italic">
            *{{ $t("legenda_194") }}
          </p>
        </v-sheet>
      </div>
    </div>

    <!--results  -->
    <v-sheet
      v-if="keySentencesLoaded > 0"
      class="py-2 px-1 mx-1 my-2 d-flex flex-column flex-grow-1 rounded-lg result-container"
    >
      <div class="d-flex justify-content-end my-1 mx-2">
        <span class="px-2 text-disabled">{{ documentStatsComputed }}</span
        >&nbsp;
        <span class="px-2 text-high-emphasis">{{ resultStatsComputed }}</span>
      </div>

      <div
        style="flex: 1; overflow-y: auto; justify-content: space-between"
        class="d-flex flex-column flex-grow-1 p-1 m-1 bg-neutral rounded-lg borders"
      >
        <div>
          <div v-for="i in this.keySentencesLoaded" :key="i">
            <v-card
              v-show="
                searchResultsComputed.items[i - 1].rating !== 'bad' ||
                this.showDismissed === 'yes'
              "
              :id="i"
              variant="outlined"
              class="card-style card-pointer rounded-lg borders"
              :class="{
                highlight: activeIndex === i,
                'elevation-10': activeIndex === i,
              }"
            >
              <v-card-title
                class="d-flex justify-space-between align-items-flex-start text-contrast title-flex-direction"
                :class="[
                  bgColor(searchResultsComputed.items[i - 1].search_result_id),
                ]"
                style="cursor: pointer !important; padding: 0.5% 1%"
              >
                <!--text header -->
                <div
                  style="
                    text-wrap: wrap;
                    display: block;
                    align-items: center;
                    cursor: pointer !important;
                  "
                >
                  <span
                    class="text-subtitle-1"
                    @click="
                      resultClicked(
                        i,
                        searchResultsComputed.items[i - 1].search_result_id
                      )
                    "
                  >
                    {{ searchResultsComputed.items[i - 1].pdf }} - page
                    {{ searchResultsComputed.items[i - 1].page }}
                  </span>

                  <div>
                    <mini-breadcrumbs
                      style="font-size: small; font-style: italic"
                      :disableLinks="true"
                      :parents="
                        searchResultsComputed.items[i - 1].folder_hierarchy
                      "
                    ></mini-breadcrumbs>
                  </div>
                </div>

                <!--buttons-->
                <div class="text-body-1 align-title-buttons">
                  <span
                    @click="
                      resultClicked(
                        i,
                        searchResultsComputed.items[i - 1].search_result_id
                      )
                    "
                    class="mx-2 text-subtitle-1"
                    >{{ i }} / {{ searchResultsComputed.items.length }}</span
                  >

                  <v-tooltip
                    location="top"
                    v-model="
                      searchResultsComputed.items[i - 1].showClipboardTooltip
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        flat
                        size="xs"
                        class="clipboard-button"
                        @click="copyToClipboard(i)"
                        @mouseleave="clipboardTooltipText = 'legenda_023'"
                      >
                        <v-icon
                          icon="mdi-content-copy"
                          :info="$t('legenda_023')"
                        ></v-icon>
                      </v-btn>
                    </template>
                    <div>{{ $t(clipboardTooltipText) }}</div>
                  </v-tooltip>

                  <v-tooltip
                    location="top"
                    v-model="searchResultsComputed.items[i - 1].showPinTooltip"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        flat
                        size="xs"
                        class="rating-button"
                        @click="
                          resultRated(
                            searchResultsComputed.items[i - 1].search_result_id,
                            'good'
                          )
                        "
                        @mouseenter="
                          pinTooltipText =
                            searchResultsComputed.items[i - 1].rating == 'good'
                              ? $t('legenda_210')
                              : $t('legenda_208')
                        "
                      >
                        <v-icon
                          :icon="
                            pinIcon(searchResultsComputed.items[i - 1].rating)
                          "
                        ></v-icon>
                      </v-btn>
                    </template>
                    <div>
                      {{ $t(pinTooltipText) }}
                    </div>
                  </v-tooltip>
                  <v-tooltip
                    location="top"
                    v-model="
                      searchResultsComputed.items[i - 1].showDismissTooltip
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        flat
                        size="xs"
                        class="rating-button ml-2"
                        @click="
                          resultRated(
                            searchResultsComputed.items[i - 1].search_result_id,
                            'bad'
                          )
                        "
                        @mouseenter="
                          dismissTooltipText =
                            searchResultsComputed.items[i - 1].rating == 'bad'
                              ? $t('legenda_214')
                              : $t('legenda_212')
                        "
                      >
                        <v-icon
                          :icon="
                            dismissIcon(
                              searchResultsComputed.items[i - 1].rating
                            )
                          "
                        ></v-icon>
                      </v-btn>
                    </template>
                    <div>
                      {{ $t(dismissTooltipText) }}
                    </div>
                  </v-tooltip>

                  <v-tooltip
                    location="start"
                    v-model="
                      searchResultsComputed.items[i - 1].showScoreTooltip
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        size="x-small"
                        class="ml-2"
                        variant="plain"
                        icon="mdi-circle"
                        :color="
                          ratingColor(
                            searchResultsComputed.items[i - 1].search_result_id
                          )
                        "
                        @click="
                          this.searchResultsComputed.items[
                            i - 1
                          ].showScoreTooltip =
                            !this.searchResultsComputed.items[i - 1]
                              .showScoreTooltip
                        "
                        @mouseleave="
                          this.searchResultsComputed.items[
                            i - 1
                          ].showScoreTooltip = false
                        "
                      >
                      </v-btn>
                    </template>
                    <div>
                      <v-icon size="xs" color="search-high">mdi-circle</v-icon>
                      - {{ $t("legenda_198") }}
                    </div>
                    <div>
                      <v-icon size="xs" color="search-mid">mdi-circle</v-icon>
                      - {{ $t("legenda_199") }}
                    </div>
                    <div>
                      <v-icon size="xs" color="search-low">mdi-circle</v-icon>
                      - {{ $t("legenda_200") }}
                    </div>
                  </v-tooltip>
                </div>
              </v-card-title>
              <!-- eslint-disable  vue/no-v-text-v-html-on-component -->
              <v-card-text
                class="p-3"
                style="cursor: pointer !important"
                @click="
                  resultClicked(
                    i,
                    searchResultsComputed.items[i - 1].search_result_id
                  )
                "
                v-html="searchResultsComputed.items[i - 1].sentence"
              >
              </v-card-text>
              <!--eslint-enable-->
            </v-card>
          </div>
        </div>

        <v-switch
          v-if="
            !moreToLoad &&
            searchResultsComputed.items.some((el) => el.rating === 'bad')
          "
          class="mx-4"
          :label="$t(switchLabelComputed)"
          v-model="showDismissed"
          false-value="no"
          true-value="yes"
          hide-details
          color="contrast-lighten-1"
        ></v-switch>
      </div>

      <div v-if="moreToLoad">
        <v-btn
          variant="outlined"
          color="button-contrast"
          @click="loadResults()"
          >{{ $t("legenda_021") }}</v-btn
        >
      </div>

      <div class="p-2 text-typo-primary" v-if="showHint">
        <i>{{ $t("legenda_022") }}</i>
      </div>
    </v-sheet>

    <!--results skeleton -->
    <v-sheet
      v-else-if="keySentencesLoaded == 0 && this.searchInProgress"
      style="height: 200px"
      class="py-2 px-1 mx-1 my-2 d-flex flex-column flex-grow-1 rounded-lg"
    >
      <v-skeleton-loader
        width="150"
        class="ml-auto bg-transparent"
        type="text"
      ></v-skeleton-loader>

      <div
        style="flex: 1; overflow-y: auto"
        class="d-flex flex-column flex-grow-1 p-1 m-1 bg-neutral rounded-lg borders"
      >
        <div v-for="i in 4" :key="i">
          <v-skeleton-loader
            class="card-style"
            type="article"
          ></v-skeleton-loader>
        </div>
      </div>
      <v-skeleton-loader
        class="bg-transparent"
        style="background-color: transparent"
        type="text"
      ></v-skeleton-loader>
    </v-sheet>
    <v-snackbar
      v-model="restoreResult"
      :location="'top'"
      :multi-line="true"
      :timeout="-1"
    >
      {{ $t("legenda_207") }}
      <v-btn
        class="mx-1"
        color="button-primary"
        flat
        @click="restoreLinkedResult()"
      >
        {{ $t("legenda_040") }}
      </v-btn>
      <v-btn
        class="mx-1"
        color="button-primary"
        flat
        @click="restoreResult = false"
      >
        {{ $t("legenda_039") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import MiniBreadcrumbsComponent from "../breadcrumbs/MiniBreadcrumbsComponent.vue";
export default {
  name: "SearchResults",
  components: {
    miniBreadcrumbs: MiniBreadcrumbsComponent,
  },

  props: {
    searchResults: null,
    searchQuery: null,
    loadingQuery: null,
    searchInProgress: null,
    showLlmSkeleton: null,
    selectedIndex: null,
  },
  computed: {
    /**
     *
     */
    resultStatsComputed() {
      const resultCount = this.searchResultsComputed.items.length;
      const text = this.$t(
        "legenda_046",
        [resultCount],
        resultCount >= 2 ? 2 : 1
      );
      return text;
    },
    /**
     *
     */
    documentStatsComputed() {
      // get documents //elkasp do metric
      const distinctDocuments = this.searchResultsComputed.items.reduce(
        (acc, item) => {
          acc.add(item["document_id"]);
          return acc;
        },
        new Set()
      );

      const text = this.$t(
        "legenda_048",
        [distinctDocuments.size],
        distinctDocuments.size >= 2 ? 2 : 1
      );
      return text.toLowerCase();
    },
    /**
     *
     */
    searchResultsComputed() {
      return this.searchResults;
    },
    /**
     *
     */
    keySentencesLoaded: {
      get() {
        return this.keySentencesCount;
      },
      set(value) {
        this.keySentencesCount = value;
      },
    },
    /**
     *
     */
    moreToLoad() {
      return (
        this.keySentencesCount < this.searchResultsComputed.items.length &&
        this.searchResultsComputed.items.length > this.loadStep
      );
    },
    /**
     *
     */
    showHint() {
      return !this.moreToLoad && this.searchResultsComputed.items.length > 0;
    },
    /**
     *
     */
    clipboardColor() {
      return this.copied ? "secondary-lighten-1" : "surface-lighten-1";
    },
    /**
     *
     */
    switchLabelComputed() {
      return this.showDismissed === "no" ? "legenda_216" : "legenda_217";
    },
  },

  watch: {
    searchResults(newValue) {
      if (newValue) {
        this.rankResults();
        this.setKeySentencesCount();
      }
    },
    "searchResults.rag": function (newVal) {
      this.rag = newVal ?? { items: [] };
    },
    selectedIndex: {
      handler(val) {
        if (val != -1) {
          this.activeIndex = val;

          clearTimeout(this.timeoutId);

          // hide tooltip after 400ms
          this.timeoutId = setTimeout(() => {
            this.searchResultsComputed.items[val - 1].showPinTooltip = false;

            this.searchResultsComputed.items[
              val - 1
            ].showDismissTooltip = false;
          }, 400);

          this.activeResultId =
            this.searchResultsComputed.items[val - 1].search_result_id;

          this.gotToSearchResult(this.activeResultId, this.activeIndex);
        }
      },
    },
  },

  data() {
    return {
      timeoutId: null,
      showDismissed: "no",
      keyMatchColors: [],
      activeResultId: null,
      resultIdToRestore: null,
      restoreResult: false,
      keySentencesCount: 0,
      loadStep: 10,
      copied: false,
      clipboardTooltipText: "legenda_023",
      pinTooltipText: "legenda_208",
      dismissTooltipText: "legenda_212",
      activeIndex: 1,
      rag: { items: [] },
    };
  },

  methods: {
    /**
     *
     */
    rankResults() {
      try {
        // select sentences to display
        this.keyMatchColors = [];
        for (var i = 0; i < this.searchResultsComputed.items.length; i++) {
          let score_i = this.searchResultsComputed.items[i].distance;
          if (score_i >= 0 || i >= 0) {
            // compute color
            var color = "";
            if (score_i < 0.1) {
              color = "search-low";
            } else if (score_i < 0.7) {
              color = "search-mid";
            } else {
              color = "search-high";
            }
            this.keyMatchColors.push({
              [this.searchResultsComputed.items[i].search_result_id]: color,
            });
          }
        }
      } catch (error) {
        // error in displaying search results
        console.log("Error in:", error);
      }
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    resultClicked(absoluteIndex, searchResultId) {
      this.activeIndex = absoluteIndex;

      this.$emit("resultClicked", searchResultId);
    },
    /**
     *
     * @param {*} search_result_id
     */
    ragAnswerClicked(searchResultId) {
      const item = this.searchResultsComputed.items.find(
        (el) => el.search_result_id == searchResultId
      );
      if (item.rating === "bad") {
        this.restoreResult = true;
        this.resultIdToRestore = searchResultId;
      } else {
        const index = this.searchResultsComputed.items.findIndex(
          (x) => x.search_result_id == searchResultId
        );
        this.activeIndex = index + 1;

        this.gotToSearchResult(searchResultId, this.activeIndex);
      }
    },
    /**
     *
     */
    gotToSearchResult(searchResultId, activeIndex) {
      this.$emit("resultClicked", searchResultId);

      // scroll into view
      document.getElementById(activeIndex).scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    },
    /**
     *
     * @param {*} index
     */
    resultRated(searchResultId, rate) {
      const result = this.searchResultsComputed.items.find(
        (element) => element.search_result_id === searchResultId
      );
      const toggleRate = result.rating == rate ? "" : rate;

      if (rate === "good") {
        this.pinTooltipText =
          toggleRate === "good"
            ? this.$t("legenda_209")
            : this.$t("legenda_211");

        result.showPinTooltip = true;
      } else if (rate === "bad") {
        this.dismissTooltipText =
          toggleRate === "bad"
            ? this.$t("legenda_213")
            : this.$t("legenda_215");

        result.showDismissTooltip = true;
      }
      this.$emit("resultRated", {
        rate: toggleRate,
        searchResultId: searchResultId,
      });

      this.activeResultId = searchResultId;
    },
    /**
     *
     * @param {*} searchResultId
     */
    bgColor(searchResultId) {
      return (
        "bg-" +
        this.keyMatchColors.find((el) => Object.hasOwn(el, searchResultId))[
          searchResultId
        ] +
        "-lighten-1"
      );
    },
    /**
     *
     * @param {*} searchResultId
     */
    ratingColor(searchResultId) {
      return this.keyMatchColors.find((el) =>
        Object.hasOwn(el, searchResultId)
      )[searchResultId];
    },
    /**
     *
     */
    loadResults() {
      if (this.keySentencesCount > this.searchResultsComputed.items.length)
        return;
      if (
        this.searchResultsComputed.items.length - this.keySentencesCount >=
        this.loadStep
      ) {
        this.keySentencesLoaded = this.keySentencesCount + this.loadStep;
      } else {
        this.keySentencesLoaded =
          this.keySentencesCount +
          (this.searchResultsComputed.items.length - this.keySentencesCount);
      }
      this.$emit("keySentencesLoaded", this.keySentencesLoaded);
    },
    /**
     *
     */
    setKeySentencesCount() {
      if (this.searchResultsComputed.items.length <= this.loadStep) {
        this.keySentencesLoaded = this.searchResultsComputed.items.length;
      } else {
        this.keySentencesLoaded = this.loadStep;
      }
    },
    /**
     *
     * @param {*} i
     */
    async copyToClipboard(i) {
      const boldRegex = new RegExp("(<b>)|(</b>)", "g");
      const textToCopy = this.searchResultsComputed.items[
        i - 1
      ].sentence.replace(boldRegex, "");

      await navigator.clipboard.writeText(textToCopy);

      this.clipboardTooltipText = "legenda_035";
      this.searchResultsComputed.items[i - 1].showClipboardTooltip = true;

      // hide tooltip after 400ms for mobile screens
      if (this.isMobile()) {
        clearTimeout(this.timeoutId);

        this.timeoutId = setTimeout(() => {
          this.searchResultsComputed.items[i - 1].showClipboardTooltip = false;
        }, 400);
      }
    },
    /**
     *
     * @param {*} rating
     */
    pinIcon(rating) {
      if (rating === "good") {
        return "mdi-pin";
      } else {
        return "mdi-pin-outline";
      }
    },
    /**
     *
     * @param {*} rating
     */
    dismissIcon(rating) {
      if (rating === "bad") {
        return "mdi-restore";
      } else {
        return "mdi-close";
      }
    },
    /**
     *
     */
    restoreLinkedResult() {
      this.restoreResult = false;

      this.$emit("restoreResult", this.resultIdToRestore);
    },
    /**
     *
     */
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  created() {
    this.rankResults();
    this.rag.items = this.searchResults.rag.items;
    if (this.searchResultsComputed.items.length > 0) {
      this.setKeySentencesCount();
    }
  },
};
</script>

<style>
.card-style {
  margin: 0.8% auto;
  word-wrap: break-word;
  cursor: pointer !important;
  width: 95%;
  background-color: white !important;
}

.header-title-style {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  flex-wrap: wrap;
  white-space: break-spaces;
}

.clipboard-button {
  background-color: transparent !important;
  margin-left: auto;
}

.rating-button {
  background-color: transparent !important;
  margin-left: 2%;
}
.highlight {
  border: #75736e !important;
  border-style: solid !important;
  border-width: medium !important;
  width: 98% !important;
}
.llm-disclaimer {
  margin: 0;
  font-size: small;
  line-height: normal;
}
.borders {
  border-style: solid;
  border-color: #efeff0;
}
.title-flex-direction {
  flex-direction: row;
}
.result-container {
  height: 200px;
}

@media (max-width: 535px) {
  .title-flex-direction {
    flex-direction: column;
  }

  .align-title-buttons {
    align-self: flex-end;
  }
  .result-container {
    height: 300px;
  }
}
</style>
