import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import users from "./modules/users";
import pdfs from "./modules/pdfs";
import searches from "./modules/searches";
import folders from "./modules/folders";
import subscriptions from "./modules/subscriptions";
import loading from "./modules/loading";
import searchQueries from "./modules/searchQueries";
import tree from "./modules/tree";

import _ from "lodash";

export const initialStoreModules = {
  users,
  pdfs,
  searches,
  folders,
  subscriptions,
  loading,
  searchQueries,
  tree,
};

const store = createStore({
  modules: _.cloneDeep(initialStoreModules),
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    resetState(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        if (state[key] !== "language") {
          state[key] = _.cloneDeep(value.state);
        } else {
          console.log(state[key]);
        }
      });
      store.commit("setLoading", false);
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
