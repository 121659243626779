

const getters = {
  stateIsLoading: state => state.isLoading,
};


const getDefaultLoadingState = () => {
  return {
    isLoading: false,
  }
}

// initial state
const state = getDefaultLoadingState();



const mutations = {
  setLoading(state, val) {
    state.isLoading = val;
  },}


export default {
  state, 
  mutations,
  getters
 
};