<template>
  <div class="d-flex flex-column">
    <v-tabs v-model="currentTab" fixed-tabs>
      <v-tab
        v-for="tab in tabs"
        :key="tab"
        :value="tab"
        style="min-width: 200px"
      >
        {{ $t(tab.title, 2) }}</v-tab
      >
    </v-tabs>

    <v-sheet
      class="flex-grow-1"
      v-model="currentTab"
      color="transparent"
      v-if="currentTab"
      :key="currentTab"
      :value="currentTab"
    >
      <keep-alive>
        <component
          :is="loadDynamicComponent(currentTab.component)"
          v-bind="currentTab.component.componentProps"
        ></component>
      </keep-alive>
    </v-sheet>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";

export default {
  name: "TabsComponent",
  props: {
    tabs: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      clickedTab: "clickedTab",
    }),
  },
  data() {
    return {
      currentTab: null,
    };
  },
  watch: {
    currentTab(newTab) {
      this.$store.commit("setClickedTab", newTab);
    },
  },
  methods: {
    /**
     *
     */
    loadDynamicComponent(component) {
      if (component.name) {
        return defineAsyncComponent(() =>
          import(`@/${component.path}/${component.name}.vue`)
        );
      }
    },
  },
  created() {
    this.currentTab = this.clickedTab;
  },
};
</script>
