<template>
  <v-menu open-on-hover open-on-click location="top" class="p-0 m-0">
    <template v-slot:activator="{ props }">
      <v-btn class="text-none h-100" append-icon="mdi-menu-down" v-bind="props"
        >{{ languageName }}
      </v-btn>
    </template>
    <v-list>
      <language-list-component></language-list-component>
    </v-list>
  </v-menu>
</template>

<script>
import LanguageListComponent from "./LanguageListComponent.vue";
export default {
  name: "LanguageComponent",
  components: {
    LanguageListComponent,
  },
  props: {
    accountColor: {
      type: String,
      default: "button-secondary",
    },
  },
  computed: {
    /**
     *
     */
    languageName() {
      return this.$root.$i18n.locale.toUpperCase();
    },
  },
};
</script>
