<template>
  <div>
    <v-list-item
      v-for="locale in languages"
      :key="`locale-${locale}`"
      :value="locale"
      @click="setLocale(locale)"
    >
      <v-list-item-title>{{ locale.toUpperCase() }}</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { SUPPORTED_LOCALES, i18nInstance } from "../../../translations/i18n.js";
import { languageService } from "../../../services/languageService.js";
export default {
  name: "LanguageListComponent",
  props: {
    accountColor: {
      type: String,
      default: "button-secondary",
    },
  },
  computed: {
    /**
     *
     */
    languageName() {
      return this.$root.$i18n.locale.toUpperCase();
    },
  },
  data() {
    return {
      languages: SUPPORTED_LOCALES,
    };
  },
  methods: {
    /**
     *
     * @param {*} item
     */
    async setLocale(item) {
      await languageService.setLocale(item, i18nInstance, this.$root.$i18n);
    },
  },
};
</script>
