<template>
  <v-container fluid class="h-100 m-0 p-0">
    <v-row no-gutters class="h-100 bg-page-primary overflow-auto">
      <v-col cols="12">
        <inspectSearch
          v-bind="guiSearchResults"
          class="h-100"
          :fileName="selectedItem.fileName"
          :searchQuery="searchQuery"
          :pdfBinary="pdfBinary"
          :searchResults="guiSearchResults"
          :searchScope="searchScopeReactive"
          :boilerplate="boilerplate"
          :searchInProgress="loading"
          :showLlmSkeleton="showLlmSkeleton"
          :selectedIndex="selectedIndex"
          @updateRating="updateRating"
          @keySentencesLoadedInspect="setKeySentencesLoaded"
          @highlightResult="showResultOnDocument"
          @changeScope="changeSearchScope"
          @search="submitSearch"
          @restoreResult="restoreResult"
        />
      </v-col>
      <v-snackbar
        class="elevation-0"
        v-model="showStatusAlertComputed"
        :location="'top'"
        :multi-line="true"
        color="primary-lighten-3"
        :timeout="-1"
      >
        {{ $t(statusAlertMessage) }}
        <template v-slot:actions>
          <v-btn class="mx-1" flat @click="userClicked = true">
            {{ $t("legenda_040") }}
          </v-btn>
        </template>
      </v-snackbar>

      <searchErrorModalComp
        :name="selectedItem.name"
        :folderId="folderToSearchComputed"
        :issueType="'documentSearch'"
        :issueInfo="{ search_query: searchQuery }"
        v-model="searchErrorModal"
        v-on:ok="searchErrorModal = false"
      />

      <errorModalCmp
        :title="'legenda_030'"
        :content="this.errorModalText"
        :okButtonLabel="'legenda_040'"
        :cancelButtonLabel="'legenda_039'"
        v-model="errorModal"
        v-on:ok="errorModal = false"
      />
    </v-row>
  </v-container>
</template>

<script>
import InspectSearch from "@/components/search/InspectSearchComponent.vue";
import { mapGetters, mapActions } from "vuex";
import SearchErrorModal from "../../components/search/SearchErrorModal.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import inspectSearchService from "./services/inspectSearchService";
import _ from "lodash";

export default {
  name: "BaseSearchResults",
  components: {
    inspectSearch: InspectSearch,
    searchErrorModalComp: SearchErrorModal,
    errorModalCmp: ModalComponent,
  },
  computed: {
    ...mapGetters({
      pdfs: "statePdfs",
      subFolders: "stateSubFolders",
      searchQuery: "stateSearchQuery",
      searchResults: "stateSearchResults",
      breadcrumbsList: "stateBreadcrumbs",
      pdfBinary: "statePdfBinary",
      searchScope: "stateSearchScope",
      tree: "stateTree",
      olympiaLexView: "olympiaLexView",
      llmSearchQueryId: "stateLLMSearchQueryId",
      llmSearchInProgress: "stateLLMSearchInProgress",
      llmSearchOption: "llmSearchOption",
      searchMode: "stateSearchMode",
    }),
    /**
     *
     */
    showLlmSkeleton() {
      // show skeleton if llm is in progress and query of llm is the current one
      return (
        this.llmSearchInProgress &&
        this.llmSearchQueryId === this.searchQueryId &&
        this.llmSearchOption
      );
    },
    /**
     *
     */
    folderToSearchComputed() {
      if (this.searchScope.length == 0) return this.tree[0].itemId;
      return this.searchScope[0].type == "folder"
        ? this.searchScope[0].itemId
        : this.searchScope[0].parentId;
    },
    /**
     *
     */
    showStatusAlertComputed() {
      return this.showStatusAlert && !this.userClicked;
    },
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    statusAlertMessage: {
      type: String,
      default: null,
    },
    showStatusAlert: {
      type: Boolean,
      default: false,
    },
    createPayload: {
      type: Function,
      default() {},
    },
    documentId: {
      type: String,
      default: null,
    },
  },
  watch: {
    "searchResults.rag": function (newVal) {
      this.guiSearchResults.rag = newVal ?? { items: [] };
    },
    searchResults(newVal) {
      this.guiSearchResults = newVal;
    },
  },
  data() {
    return {
      guiSearchResults: null,
      selectedIndex: 1,
      searchQueryId: false,
      loading: false,
      userClicked: false,
      documentIdReactive: "",
      searchScopeReactive: null,
      boilerplate: false,
      keySentencesLoaded: 10, // this is the same step as in the search result component
      errorModalText: "legenda_033",
      errorModal: false,
      searchErrorModal: false,
      selectedItem: {
        fileName: null,
        page: null,
        document_id: null,
        keySentence: null,
        searchMode: null,
      },
    };
  },
  methods: {
    ...mapActions([
      "search",
      "queryLLM",
      "highlightedPdf",
      "getPdf",
      "getSearchResultsByQuery",
      "submitRating",
    ]),
    /**
     *
     */
    async submitSearch() {
      if (!this.searchQuery) return;

      if (this.searchScope[0].type === "folder") {
        this.$store.commit("setPdfBinary", new ArrayBuffer(0));
      }

      this.loading = true;
      // --- set up ---
      this.page = 1;
      this.boilerplate = false;

      // search
      try {
        await this.search({
          parentFolderId: this.folderToSearchComputed,
          documentId: this.documentIdReactive,
          key: this.searchQuery,
          llmSearch: this.llmSearchOption,
        });

        if (this.llmSearchOption == true && this.searchMode === "semantic") {
          this.queryLLM({
            searchQueryId: this.guiSearchResults.search_query_id,
          });
        }

        this.loading = false;

        this.$router.push({
          name: "SearchResults",
          params: {
            search_query_id: this.guiSearchResults.search_query_id,
          },
          state: {
            document_id: this.documentIdReactive,
            searchQuery: this.searchQuery,
          },
        });
      } catch (error) {
        // error in search
        this.searchErrorModal = true;
        this.loading = false;
      }
    },

    /**
     * ok
     * @param {*} param0
     */
    async updateRating({ rate, searchResultId }) {
      const previouslySelectedIndex = this.selectedIndex;
      // reset the index, so that we can update the document page
      this.selectedIndex = -1;

      await this.submitRating({
        search_result_id: searchResultId,
        rating: rate,
      });

      this.guiSearchResults.items.find(
        (el) => el.search_result_id === searchResultId
      ).rating = rate;

      this.searchResults.items.find(
        (el) => el.search_result_id === searchResultId
      ).rating = rate;

      const dismissedIndex =
        this.guiSearchResults.items.findIndex(
          (el) => el.search_result_id === searchResultId
        ) + 1;

      // sort list first by rate then by rank
      this.guiSearchResults.items.sort((a, b) => {
        const rateOrder = { good: 1, "": 2, bad: 3 };
        if (rateOrder[a.rating] !== rateOrder[b.rating]) {
          return rateOrder[a.rating] - rateOrder[b.rating];
        }

        // If rates are the same, sort by rank in ascending order
        return a.rank - b.rank;
      });

      //if rating is bad, active element is the last selected element or the first element in case the dismissed index was selected

      const newSelectedIndex =
        this.guiSearchResults.items.findIndex(
          (el) => el.search_result_id === searchResultId
        ) + 1;

      this.selectedIndex =
        rate === "bad"
          ? dismissedIndex === previouslySelectedIndex
            ? 1
            : previouslySelectedIndex
          : newSelectedIndex;
    },
    /**
     * ok
     * @param {*} keySentencesLoaded
     */
    async setKeySentencesLoaded(keySentencesLoaded) {
      // every time the results are expanded,
      // update progressively the highlighted text
      this.keySentencesLoaded = keySentencesLoaded;

      await inspectSearchService.highlightPdf(
        this.guiSearchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    async showResultOnDocument(params) {
      this.$store.commit("setPdfBinary", new ArrayBuffer(0));

      await inspectSearchService.showResultOnDocument(
        params["searchResultId"],
        this.guiSearchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );

      params["goToPage"](params["searchResultId"]);
    },
    /**
     *
     * @param {*} item
     */
    changeSearchScope(item) {
      this.documentIdReactive = item.type === "folder" ? "" : item.itemId;
    },
    /**
     *
     */
    setGuiSearchResults() {
      this.guiSearchResults = _.cloneDeep(this.searchResults);

      this.guiSearchResults.items.sort(function (a, b) {
        const rateOrder = { good: 1, "": 2, bad: 3 };
        if (rateOrder[a.rating] !== rateOrder[b.rating]) {
          return rateOrder[a.rating] - rateOrder[b.rating];
        }

        // If rates are the same, sort by rank in ascending order
        return a.rank - b.rank;
      });
    },
    /**
     *
     * @param {*} searchResultId
     */
    async restoreResult(searchResultId) {
      // restore the rating
      await this.updateRating({ rate: "", searchResultId: searchResultId });
      this.selectedIndex =
        this.guiSearchResults.items.findIndex(
          (el) => el.search_result_id === searchResultId
        ) + 1;
    },
  },
  created() {
    this.searchQueryId = Number(
      this.$router.currentRoute.value.params.search_query_id
    );
    this.setGuiSearchResults();
  },
  async mounted() {
    this.$store.commit("setPdfBinary", new ArrayBuffer(0));
    this.documentIdReactive = this.documentId;
    this.searchScopeReactive = this.searchScope;

    if (this.guiSearchResults.items.length > 0) {
      // load the file
      await inspectSearchService.loadPdf(
        this.guiSearchResults.items,
        this.selectedItem,
        this.keySentencesLoaded,
        this.highlightedPdf,
        this.createPayload
      );
    } else {
      //if we are in pdf mode fetch the pdf, no results
      if (this.documentId) {
        // set also the name, take it from pathArray because results are empty
        this.selectedItem.fileName = window.history.state.fileName;

        await this.getPdf(this.documentIdReactive);
      } else {
        // reset pdf
        this.boilerplate = true;
        this.$store.commit("setPdfBinary", new ArrayBuffer(0));
      }
    }
  },
};
</script>
