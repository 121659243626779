import axios from "axios";
import router from "../../router/index";
import store from "../../store";

class AxiosSingleton {
  /**
   * private members
   */
  static #instance;

  /**
   *
   * @returns
   */

  constructor() {
    // return instance if exists
    if (AxiosSingleton.#instance) return AxiosSingleton.#instance;

    // create instance
    AxiosSingleton.#instance = this;

    // init and configure axios
    this.initAxios();
  }
  /**
   *
   * @returns the instance of the class
   */
  static instance() {
    if (!AxiosSingleton.#instance) return new AxiosSingleton();

    return AxiosSingleton.#instance;
  }
  /**
   *
   * @returns
   */
  getAxiosInstance() {
    return this.axiosInstance;
  }
  /**
   *
   */
  initAxios() {
    this.axiosInstance = axios.create({
      withCredentials: true,
      baseURL: process.env.VUE_APP_API_URL,
    });

    this.configureAxios();
  }
  /**
   * Configure interceptors
   */
  configureAxios() {
    this.axiosInstance.interceptors.request.use((config) => {
      if (!config.url) {
        return config;
      }

      if (this.useSpinner(config.url, config.method)) {
        store.commit("setLoading", true);
      }

      const currentUrl = new URL(config.url, config.baseURL);
      // parse pathName to implement variables
      Object.entries(config.urlParams || {}).forEach(([k, v]) => {
        currentUrl.pathname = currentUrl.pathname.replace(
          `:${k}`,
          encodeURIComponent(v)
        );
      });
      return {
        ...config,
        url: currentUrl.pathname,
      };
    });

    // redirect to the /login route once the access token expires
    this.axiosInstance.interceptors.response.use(
      (response) => {
        response.baseURL;
        store.commit("setLoading", false);
        return response;
      },

      async (error) => {
        if (error) {
          const originalRequest = error.config;
          store.commit("setLoading", false);

          if (error.response && error.response.status === 401) {
            if (!originalRequest._retry) {
              originalRequest._retry = true;
              try {
                await this.axiosInstance.post(
                  `/refresh`,
                  {},
                  { withCredentials: true }
                );

                // Retry the original request
                return this.axiosInstance(originalRequest);
              } catch (refreshError) {
                // do nothing
              }

              const user = { ...store.getters.stateUser };
              if (user) {
                if (user.subscription_tier === "olympialex_review") {
                  // go back to olympialex
                  store.commit("resetState");
                  window.location.href =
                    "https://www.olympialex.com/olympialex_review.php";
                } else {
                  // normal user, redirect to login
                  store.commit("resetState");
                  return router.push("/login");
                }
              }
            } else {
              return router.push("/login");
            }
          } else {
            throw error;
          }
        }
      }
    );
  }

  useSpinner(url, method) {
    const urlsToOmit = [
      { url: "/pdf_binary/poll", method: "get" },
      { url: "/login/", method: "post" },
      { url: "/pdf_binary/all", method: "get" },
      { url: "/pdf_binary/", method: "post" },
      { url: "/pdf_binary/", method: "get" },
      { url: "/folder/", method: "get" },
      { url: "/search_query/all", method: "get" },
      { url: "/search_results", method: "get" },
      { url: "/llm_answer", method: "get" },
      { url: "/search/highlighted_pdf/", method: "post" },
      { url: "/refresh", method: "post" },
      { url: "/search", method: "post" },
      { url: "/search", method: "post" },
      { url: "/subscription/checkout_complete", method: "get" },
    ];
    const found = urlsToOmit.some(
      (element) => url.includes(element.url) && element.method.includes(method)
    );
    return !found;
  }
}

export default AxiosSingleton;
