const groups = {
  admin: ["legenda@master", "olympialex@master"],
  dev: ["dev"],
  legenda: ["professional", "enterprise"],
  legenda_plus: ["enterprise"],
};

const views = {
  customerPortalView: {
    groups: [groups.admin, groups.dev, groups.legenda].flat(),
  },
  shareView: {
    groups: [groups.admin, groups.dev, groups.legenda_plus].flat(),
  },
  subscriptionEndView: {
    groups: [groups.admin, groups.dev].flat(),
  },
};

/**
 *
 * @param {*} view
 * @param {*} tier
 */
function hasView(view, tier) {
  const groups = views[view].groups;
  const isUserAuthorized = groups.includes(tier);
  return isUserAuthorized;
}

export { hasView };
