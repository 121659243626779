<template>
  <v-row class="bg-page-secondary h-100 m-auto">
    <v-col
      cols="12"
      class="p-0 d-flex align-items-center flex-column"
      style="justify-content: space-between"
    >
      <div class="w-100 m-auto">
        <v-img :src="logoImage" class="logo-style"></v-img>
      </div>
      <div class="h2 p-5" v-if="!showAlert">
        {{ $t("legenda_026") }}
      </div>

      <alert v-if="showAlert" :variant="'warning'" :messageList="alertMessages"></alert>

      <div class="mt-5">
        <metis_footer :fontColor="'text-typo-primary'" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AlertComponent from "../../../components/AlertComponent.vue";
import FooterComponent from "../../../components/FooterComponent.vue";
import logoImg from "../../../assets/images/legenda-logo.svg";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "OlympiaLexLoginView",
  components: {
    alert: AlertComponent,
    metis_footer: FooterComponent,
  },
  props: ["token"],
  data() {
    return {
      alertMessages: [],
      showAlert: false,
      logoImage: logoImg,
    };
  },

  computed: {
    ...mapGetters({ olympiaLexView: "olympiaLexView" }),
  },

  methods: {
    ...mapActions(["olympiaLexLogin"]),
    async login() {
      try {
        await this.olympiaLexLogin(this.token);
        if (this.olympiaLexView) {
          this.$router.push("/olympialex/dashboard");
        } else {
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.alertMessages = [
          {
            text: "legenda_025",
            links: [
              {
                href: "mailto:info@metisai.it",
                placeholder: this.$t("legenda_087"),
                openInTab: false,
              },
            ],
          },
        ];
        this.showAlert = true;
      }
    },
  },

  async created() {
    await this.login();
  },
};
</script>
<style>
.logo-style {
  height: 8vh;
}
</style>
