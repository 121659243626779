import { createPlausible } from "v-plausible/vue";

const plausible = createPlausible({
  init: {
    domain: process.env.VUE_APP_LEGENDA_DOMAIN,
    apiHost: "https://plausible.io",
    trackLocalhost: false,
  },
  settings: {
    enableAutoOutboundTracking: true,
    enableAutoPageviews: false,
  },
  partytown: false,
});

export { plausible };
