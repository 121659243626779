<template>
  <div
    class="d-flex flex-row flex-wrap p-1 m-1 justify-content-space-between"
    style="gap: 5px"
  >
    <v-btn flat class="black-button" rounded="xl" href="/subscriptions">{{
      $t("legenda_159")
    }}</v-btn>
    <v-btn flat class="white-button" rounded="xl" href="/login">{{
      $t("legenda_160")
    }}</v-btn>
  </div>
</template>
<script>
export default {
  name: "AuthButtonsComponent",
};
</script>
<style>
.black-button {
  background-color: black !important;

  color: white !important;
  text-decoration: none !important;
  max-width: 100%;
}
.white-button {
  background-color: white !important;
  border-width: thin !important;
  border-color: black !important;

  color: black !important;
  text-decoration: none !important;
}
</style>
