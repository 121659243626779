import { createI18n } from "vue-i18n";
import seoTranslations from "./seo/seoTranslations.json";

const SUPPORTED_LOCALES = Object.keys(seoTranslations);

class I18nSigleton {
  static #i18nInstance;

  constructor() {
    // return instance if exists
    if (I18nSigleton.#i18nInstance) return I18nSigleton.#i18nInstance;

    // create instance
    I18nSigleton.#i18nInstance = this;
    this.initI18n();
  }

  /**
   *
   * @returns
   */
  getInstance() {
    return this.i18nInstance;
  }

  /**
   *
   * @param {*} options
   * @returns
   */
  initI18n(
    options = {
      fallbackLocale: "en",
      legacy: false,
    }
  ) {
    options.locale = this.setupLocale(options.fallbackLocale);

    // create the module
    const i18nInstance = createI18n(options);

    this.i18nInstance = i18nInstance;
  }
  /**
   *
   * @param {*} fallbackLocale
   * @returns
   */
  setupLocale(fallbackLocale) {
    //language from local storage
    const language = localStorage.getItem("language");

    // get the browser locale
    const userLanguage = language
      ? language
      : window.navigator.language ||
        navigator.userLanguage ||
        navigator.language;

    // check if it belongs to the supported locales
    const strippedLanguageCode = userLanguage.split("-")[0].toLowerCase();

    // if it doesn't belong, use as default the fallback locale, which is english
    const locale = SUPPORTED_LOCALES.includes(strippedLanguageCode)
      ? strippedLanguageCode
      : fallbackLocale;

    return locale;
  }
}

const i18nSingleton = new I18nSigleton();
const i18nInstance = i18nSingleton.getInstance();

export { i18nInstance, SUPPORTED_LOCALES };
