import store from "../store";
import { useHead } from "@vueuse/head";
import seoTranslations from "../translations/seo/seoTranslations.json";
import { nextTick } from "vue";

class LanguageService {
  /**
   *
   * @param {*} locale
   * @param {*} i18nInstance
   * @param {*} i18nRoot
   */
  async setLocale(locale, i18nInstance, i18nRoot) {
    // update store and local storage
    store.commit("setLanguage", locale);
    localStorage.setItem("language", locale);

    // load trasnlations and set the new locale
    await this.loadLocaleMessages(i18nInstance, locale);
    i18nRoot.locale = locale;
  }
  /**
   *
   * @param {*} i18nInstance
   * @param {*} locale
   * @returns
   */
  async loadLocaleMessages(i18nInstance, locale) {
    // load locale messages with dynamic import
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `../translations/locales/${locale}.json`
    );

    // set locale and locale message
    i18nInstance.global.setLocaleMessage(locale, messages.default);

    // update the head
    this.setI18nLanguage(i18nInstance, locale);

    return nextTick();
  }
  /**
   *
   * @param {*} i18nInstance
   * @param {*} locale
   * @param {*} fallbackLocale
   */
  async loadStartUpMessages(i18nInstance, locale, fallbackLocale) {
    // load the fallback messages no matter what (in case some translations are missing)
    await this.loadLocaleMessages(i18nInstance, fallbackLocale);

    // if the browser locale is different from the fallback, load it
    // (otherwise the user will load it dynamicall when they will change it from the dropdown menu)
    if (locale !== fallbackLocale)
      await this.loadLocaleMessages(i18nInstance, locale);
  }

  /**
   *
   * @param {*} i18n
   * @param {*} locale
   */
  setI18nLanguage(i18n, locale) {
    i18n.global.locale.value = locale;

    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */

    // here do the meta thingy
    const { title, description } = seoTranslations[locale];

    useHead({
      title: title,
      meta: [
        {
          name: "description",
          content: description,
        },
      ],
    });
  }

  constructor() {}
}

const languageService = new LanguageService();
export { languageService };
