<template>
  <errorModal
    :title="this.errorModalTitle"
    :content="this.errorModalText"
    :okButtonLabel="'legenda_040'"
    :cancelButtonLabel="'legenda_039'"
    v-model="dialog"
    v-on:ok="onOk"
  ></errorModal>
</template>

<script>
import ModalComponent from "../../components/ModalComponent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ olympiaLexView: "olympiaLexView" }),
  },
  components: {
    errorModal: ModalComponent,
  },
  data() {
    return {
      errorModalTitle: "legenda_027",
      errorModalText: "",
      dialog: false,
    };
  },

  props: {
    issueType: {
      type: String,
      default: "",
    },
    issueInfo: null,
    folderId: null,
    name: null,
    searchErrorModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions(["reportIssue"]),
    /**
     *
     */
    async submitIssue() {
      try {
        // send request
        await this.reportIssue({
          name: this.name,
          folderId: this.folderId,
          issueType: this.issueType,
          issueInfo: this.issueInfo,
        });
      } catch (error) {
        console.log(error);
      }
    },
    /**
     *
     */
    async onOk() {
      await this.submitIssue();
      this.$store.commit("setSearchResults", null);
      this.$emit("issueSubmitted");
    },
  },
  async created() {
    this.errorModalText = this.olympiaLexView ? "legenda_027" : "legenda_029";
  },
};
</script>
