<template>
  <v-row class="bg-page-secondary h-100 m-auto">
    <v-col
      cols="12"
      class="p-0 d-flex align-items-center flex-column"
      style="justify-content: space-between"
    >
      <div class="h2 m-5 p-5">{{ $t("legenda_016") }}</div>
      <v-btn
        style="font-size: x-large"
        variant="plain"
        @click="this.$router.back()"
        size="x-large"
        prepend-icon="mdi-arrow-left"
      >
        {{ $t("legenda_017") }}
      </v-btn>

      <div class="mt-5">
        <metis_footer />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "PageNotFound",

  components: {
    metis_footer: FooterComponent,
  },
};
</script>
