import axiosWrapper from "../../plugins/axios/axiosWrapper";

const getDefaultSearchState = () => {
  return {
    searchResults: null,
    searchQuery: null,
    llmSearchQueryId: null,
    llmSearchInProgress: false,
    searchMode: null,
  };
};

// initial state
const state = getDefaultSearchState();

const getters = {
  stateSearchResults: (state) => state.searchResults,
  stateSearchQuery: (state) => state.searchQuery,
  stateLLMSearchQueryId: (state) => state.llmSearchQueryId,
  stateLLMSearchInProgress: (state) => state.llmSearchInProgress,
  stateSearchMode: (state) => state.searchMode,
};

const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  // eslint-disable-next-line no-empty-pattern
  async submitRating({}, { search_result_id, rating }) {
    let payload = {
      search_result_id: search_result_id,
      rating: rating,
    };

    return await axiosWrapper.post("/search/rate", payload);
  },
  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  async search({ commit }, { parentFolderId, documentId, key, llmSearch }) {
    const payload = {
      folder_id: parentFolderId,
      document_id: documentId,
      searchKey: key,
      llm_search: llmSearch,
    };

    const response = await axiosWrapper.post(`/search/`, payload);

    commit("setSearchResults", response.data);
  },

  async queryLLM({ commit }, { searchQueryId }) {
    try {
      commit("setLLMScope", {
        queryId: searchQueryId,
        llmSearchInProgress: true,
      });

      const response = await longPolling(
        () =>
          axiosWrapper.get(
            `/search_query/:search_query_id/llm_answer`,
            { search_query_id: searchQueryId },
            null,
            { "Content-Type": "application/json" }
          ),
        2000,
        30
      );

      commit("setLLMAnswer", response.data);
      commit("setLLMScope", { queryId: null, llmSearchInProgress: false });
    } catch (error) {
      // Do nothing?
    }
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   */
  // eslint-disable-next-line no-empty-pattern
  async highlightedPdf({}, { documentId, keyElements }) {
    const payload = {
      document_id: documentId,
      key_elements: keyElements,
    };

    return await axiosWrapper.post(
      `/search/highlighted_pdf/`,
      payload,
      null,
      "arraybuffer"
    );
  },
  /**
   *
   * @param {*} param0
   * @returns
   */
  // eslint-disable-next-line no-empty-pattern
  async reportIssue({}, { name, folderId, issueType, issueInfo }) {
    const payloadData = {
      name: name,
      folder_id: folderId,
      issue_type: issueType,
      issue_info: issueInfo,
    };

    try {
      return await axiosWrapper.post("/reported_issue/", payloadData);
    } catch (error) {
      return false;
    }
  },
};

const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} payload
   */
  setSearchResults(state, payload) {
    state.searchResults = payload;
  },
  /**
   *
   * @param {*} state
   * @param {*} payload
   * @returns
   */
  setLLMAnswer(state, payload) {
    if (!state.searchResults) {
      return;
    }

    // this can be any llm answer
    // update the value only if the current loaded values
    // belong to the same llm search query
    if (state.llmSearchQueryId === state.searchResults.search_query_id) {
      state.searchResults.rag = payload;
    }
  },
  /**
   *
   * @param {*} state
   * @param {*} searchQuery
   */
  setSearchQueryObject(state, searchQueryObject) {
    state.searchQuery = searchQueryObject.searchQuery.trim();
    state.searchMode = searchQueryObject.searchMode;
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  setLLMScope(state, { queryId, llmSearchInProgress }) {
    state.llmSearchQueryId = queryId;
    state.llmSearchInProgress = llmSearchInProgress;
  },

  /**
   *
   * @param {*} state
   */
  resetSearchState(state) {
    Object.assign(state, getDefaultSearchState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

const longPolling = async (axiosCallFunc, interval = 2000, maxRetries = 10) => {
  let count = 0;
  while (count <= maxRetries) {
    count++;

    try {
      const response = await axiosCallFunc();
      return response;
    } catch (error) {
      // Do nothing
    } finally {
      await new Promise((r) => setTimeout(r, interval));
    }
  }

  throw new Error("Polling finished unsuccessfully");
};
