<template>
  <v-row dense class="bg-page-primary">
    <v-col
      cols="12"
      xl="6"
      lg="6"
      md="6"
      sm="10"
      xs="10"
      class="mx-auto p-4"
      style="justify-content: space-evenly"
    >
      <v-card
        id="terms"
        class="px-2 overflow-visible"
        style="display: grid; text-align: justify"
        color="surface"
      >
        <v-btn
          v-if="history"
          class="close-popup"
          icon="mdi-close"
          alt="Close"
          @click="this.$router.back()"
          size="x-small"
          color="button-primary"
        ></v-btn>

        <v-card-title class="h4 m-auto text-center text-wrap"
          >General Terms & Conditions of the service "LEGENDA"
        </v-card-title>
        <v-card-text>
          <ol>
            <li>
              <strong>Subject Matter</strong>
              <ol>
                <li>
                  These general terms and conditions of contract govern the
                  relationship between Metis Srl, with registered office in
                  Milan, Piazza Sicilia 8, ("Metis") and the user of the Legenda
                  service (hereinafter referred to as "User" and "Service"
                  respectively).
                  <ol>
                    <li>
                      The contract between Metis and the User is considered
                      concluded only upon the specific approval of the clauses
                      referred to in points 1.5, 1.6, 1.8, 2.2, 2.3, 2.5, 3.6,
                      5.1, 6.2, 7.1, and 7.2, even if such approval occurs at a
                      later time after the approval of these general terms and
                      conditions and after the User's performance of the
                      consideration referred to in point 1.6. Therefore, it is
                      understood that if the User does not specifically approve
                      the aforementioned clauses, Metis will not provide the
                      Service, and the User will be entitled to a refund of any
                      consideration already paid or performed.
                    </li>
                  </ol>
                </li>
                <li>
                  The Service consists of a web application that searches for
                  content within documents uploaded by the User, based on search
                  inputs provided by the User ("Query").
                </li>
                <li>
                  The Service, in particular, performs an automatic indexing of
                  the content of the uploaded electronic documents - including
                  optical character recognition ("OCR") for image format
                  documents - at the time of upload by the User. Subsequently,
                  when the User enters the Query, the Service returns results to
                  the User consisting of references to the content of individual
                  electronic documents uploaded by the User, ordered by
                  relevance to the Query.
                </li>
                <li>
                  The Service is provided to the User by Metis through the
                  activation of a pair of credentials (username and password,
                  "the Credentials") that allow access and use of the web
                  application described in the preceding points.
                </li>
                <li>
                  The User is obligated to securely keep the Credentials and not
                  to transfer them to third parties for any reason without
                  Metis' authorization. In the event of loss, theft, or
                  compromise of the Credentials, the User is obliged to notify
                  Metis to facilitate their replacement, without prejudice to
                  Metis' assessment of the User's responsibility for the purpose
                  of the possible termination of the contract.
                </li>
                <li>
                  The activation of the Credentials, if the Service requires
                  payment of consideration by the User or the performance of any
                  other consideration by the User ( such obligations of the User
                  may arise from agreements between Metis and the User or from
                  the conditions indicated on the Service's webpage,
                  particularly during the Credentials activation procedure), is
                  subject to the execution of such consideration. Therefore,
                  Metis reserves the right to suspend or permanently deactivate
                  the Credentials, even after activation, without notice, if the
                  User fails to fulfill such obligation.
                </li>
                <li>
                  In the event of termination of the contract for any reason,
                  including expiration of the final term, termination by any
                  party, if permitted, resolution or rescission, as well as in
                  the event of a declaration of nullity or cancellation, the
                  Credentials will be immediately deactivated.
                </li>
                <li>
                  The duration of the Service and, therefore, of the
                  Credentials, is governed by the agreements between Metis and
                  the User or the conditions indicated on the Service's webpage,
                  particularly during the Credentials activation procedure. In
                  the absence of a specific final term, both parties may
                  terminate the contract at any time upon one working day's
                  notice.
                </li>
              </ol>
            </li>

            <li>
              <strong>Specific Features of the Service</strong>
              <ol>
                <li>
                  The Service, as described particularly in points 1.2 and 1.3
                  above, functions through the execution of server-side software
                  developed by Metis, which uses artificial intelligence ("AI")
                  algorithms during the indexing phase and subsequent search of
                  the User's Query. Furthermore, the Service allows searching
                  based on Queries not strictly based on keywords by
                  interpreting the User's request through AI.
                </li>
                <li>
                  In light of the above, the User acknowledges that while Metis
                  is obligated to keep the Credentials active for the duration
                  of the relationship with the User and to ensure the Service's
                  operability (subject to the User providing, at their own
                  expense, the necessary devices and technological resources to
                  access the Service), Metis does not directly perform the
                  indexing and search described and is therefore not obligated
                  to provide a minimum level of reliability for the search
                  results, also referring to completeness or relevance of the
                  results provided.
                </li>
                <li>
                  Consequently, Metis cannot be held liable in any way for the
                  search results provided by the Service, also regardless of the
                  User's Query. Therefore, the User is aware of the need to
                  verify the results obtained through traditional search
                  techniques, at their sole discretion.
                </li>
                <li>
                  Notwithstanding the above, Metis will endeavor to maintain a
                  high level of reliability of the Service by adopting
                  state-of-the-art technologies. In this regard, the User
                  acknowledges that the Service may undergo aesthetic,
                  functional, or other types of changes during the duration of
                  the contract.
                </li>
                <li>
                  Considering the highly technological nature of the Service,
                  the User acknowledges that temporary interruptions may occur
                  due to scheduled or urgent maintenance interventions, as well
                  as other unforeseeable events. In the event of a Service
                  interruption lasting more than 48 hours, and only in the case
                  of a fixed-term contract under point 1.8 above, the contract
                  with the User will be extended by a duration equal to the time
                  of the interruption. Metis will endeavor to promptly notify
                  any interruption through the Service's webpage.
                </li>
              </ol>
            </li>

            <li>
              <strong>Electronic Documents and Intellectual Property</strong>
              <ol>
                <li>
                  The Service, as described in points 1.2 and 1.3 above, is
                  based, among other things, on the upload of electronic
                  documents by the User to the memory areas made available by
                  Metis for the functioning of the Service.
                </li>
                <li>
                  The User expressly guarantees the lawfulness of the uploaded
                  electronic documents and their content, as well as the full
                  right to process them, acknowledging their exclusive
                  responsibility and indemnifying Metis from any liability in
                  this regard.
                </li>
                <li>
                  The User acknowledges that the use of the Service requires
                  Metis' systems to access the electronic documents uploaded by
                  the User. Furthermore, for the exclusive purposes of
                  assistance and development, Metis' technical staff may access
                  the same documents. By uploading the electronic documents via
                  the web application, the User expressly authorizes such
                  access.
                </li>
                <li>
                  Metis undertakes to keep secure access to the electronic
                  documents uploaded by the User, not to disclose them, and not
                  to access them for reasons other than those mentioned in the
                  clause above. A summary of the security measures implemented
                  can be consulted by the User on the Service's webpage.
                </li>
                <li>
                  Without prejudice to the User's exclusive rights over the
                  content of the uploaded electronic documents, with the
                  relative liabilities of the User, the User expressly
                  acknowledges that they do not have any intellectual property
                  rights, industrial rights, or any other rights over the
                  software, search algorithms, search results, or any other
                  part, technology, phase, or product related to the Service,
                  except for access and use of the Service limited to the
                  Service's duration under point 1.8 above.
                </li>
                <li>
                  The User agrees to use the Service exclusively for their own
                  professional or business activities. In particular, the User
                  undertakes not to use the Service for commercial purposes in
                  competition with Metis' activities, not to disclose the search
                  results or any details about the Service's functioning, and
                  not to use Metis' name or trademarks, even unregistered,
                  without Metis' written authorization.
                </li>
                <li>
                  The Service is provided through a web application, in the form
                  of “software as a service”. Under no circumstances does the
                  User have the right to copies, physical or digital, of the
                  software used by Metis to provide the Service, nor the right
                  to access, view, or acquire knowledge, in any form and for any
                  reason, of the source code, search algorithms, or any other
                  part, technology, or function of the Service, which remain the
                  exclusive property of Metis.
                </li>
                <li>
                  The User undertakes to keep copies of every uploaded document
                  and acknowledges that the Service has solely the purposes
                  described in points 1.2 and 1.3 above. It is not Metis'
                  obligation to provide hosting or backup services for the
                  User's electronic documents beyond what is strictly necessary
                  for the functioning of the Service as described above.
                </li>
              </ol>
            </li>
            <li>
              <strong
                >Express Termination Clause (Clausola risolutiva
                espressa)</strong
              >
              <ol>
                <li>
                  The User's failure to comply with the obligations stated in
                  points 1.5 (preservation of credentials), 1.6 (payment of
                  consideration or performance of obligations), 3.2 (uploading
                  illicit or unauthorized documents), and 3.6 (unauthorized use
                  of the Service) will entitle Metis to terminate the contract
                  in accordance with Article 1456 of the Italian Civil Code. The
                  User also has the right to terminate the contract if the
                  Service is interrupted for a period exceeding 7 consecutive
                  days, provided that such interruption is continuous or
                  repeated to the extent that it substantially renders access to
                  the Service impossible throughout the aforementioned period.
                </li>
              </ol>
            </li>
            <li>
              <strong>Prohibition of Assignment</strong>
              <ol>
                <li>
                  The User may not substitute third parties in the relationships
                  arising from the contract with Metis, nor assign, in whole or
                  in part, the rights deriving from the contract, nor the
                  Credentials received, to third parties.
                </li>
              </ol>
            </li>
            <li>
              <strong>Communications</strong>
              <ol>
                <li>
                  All communications related to the contract between the User
                  and Metis will be sent via certified email (PEC) to Metis at
                  the address 1metissrl@legalmail.it and to the User at the PEC
                  address provided by the User during registration or
                  subsequently.
                </li>
                <li>
                  In the event that the User does not provide a PEC address,
                  Metis is authorized to communicate via non-certified email to
                  the address provided during registration with the User waiving
                  the right to claim non-receipt.
                </li>
              </ol>
            </li>
            <li>
              <strong>Jurisdiction, Venue, Applicable Law</strong>
              <ol>
                <li>
                  Any disputes concerning the interpretation, execution, or
                  termination of the contract between the User and Metis, or any
                  matter related to it in any way, are exclusively subject to
                  the jurisdiction of the Italian Judicial Authority, with
                  exclusive jurisdiction of the Court of Milan, expressly
                  excluding any other concurrent jurisdiction.
                </li>
                <li>
                  These general terms and conditions, as well as the contract
                  between the User and Metis in all its aspects, including
                  interpretation, validity, and performance, are exclusively
                  governed by Italian law.
                </li>
              </ol>
            </li>
            <li>
              <strong> Provision of the Service through Third Parties</strong>
              <ol>
                <li>
                  If the Service, even if organized by Metis, is provided to the
                  User through parties other than Metis or on webpages not
                  directly attributable to Metis, but to other entities, the use
                  of the Service will be governed by agreements between the User
                  and those different parties.
                </li>
                <li>
                  In any case, the User is also obligated towards Metis to
                  comply with the provisions of points 1.5, 3.2, 3.6, 3.8, 6.1,
                  6.2, 7.1, and 7.2, if applicable.
                </li>
              </ol>
            </li>
          </ol>
        </v-card-text>
        <v-card-actions v-if="history">
          <v-btn
            class="m-auto"
            variant="flat"
            color="button-primary"
            alt="Close"
            @click="this.$router.back()"
          >
            {{ $t("legenda_196") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TermsConditionsComponentEn",
  computed: {
    history() {
      const state = window.history.state.back;
      return state;
    },
  },
};
</script>

<style>
#terms ol {
  counter-reset: item;
  padding: 0.5em;
}
#terms li {
  display: block;
  margin: 0.5em 0;
}
#terms li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  font-weight: bold;
}
</style>
