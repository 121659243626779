<template>
  <v-container fluid class="h-100 m-0 p-0">
    <v-row
      class="bg-page-primary h-100 m-0 overflow-hidden"
      @drop.prevent="dragover = false"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <v-col cols="11" class="d-flex flex-column mx-auto p-3 my-2 h-100">
        <!-- upload -->

        <div class="px-auto" style="height: fit-content" ref="fileUpload">
          <fileUpload
            v-on:filesUploaded="submitFiles"
            :filesQueued="filesQueued"
          ></fileUpload>
        </div>

        <tabs
          :tabs="dashboardTabs"
          v-if="currentFolderId"
          class="flex-grow-1 my-1 py-2"
        ></tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FileUploadComponent from "../../../components/upload/FileUploadComponent.vue";
import TabsComponent from "@/components/TabsComponent.vue";
import app from "../../../main.js";
import _ from "lodash";

export default {
  name: "DashboardView",

  components: {
    fileUpload: FileUploadComponent,
    tabs: TabsComponent,
  },
  data() {
    return {
      homeFolder: this.$t("legenda_024"),
      file: undefined,
      filesQueued: false,
      dragover: false,
      dashboardTabs: [
        {
          id: 0,
          descr: "documents",
          title: "legenda_048",
          component: {
            path: "views/dashboard/components/dashboardTabs/document/legenda",
            name: "DocumentsTabComponent",
            componentProps: {},
          },
        },
        {
          id: 1,
          descr: "searches",
          title: "legenda_049",
          component: {
            path: "views/dashboard/components/dashboardTabs/query",
            name: "SearchQueriesTabComponent",
            componentProps: {},
          },
        },
      ],
    };
  },
  computed: {
    /**
     *
     */
    ...mapGetters({
      currentFolderId: "stateCurrentFolderId",
      homeId: "stateHomeId",
      breadcrumbsList: "stateBreadcrumbs",
      searchScope: "stateSearchScope",
    }),
  },
  props: {
    folder_id: {
      type: Number,
      default: null,
    },
  },

  methods: {
    /**
     *
     */
    ...mapActions(["home", "initTree"]),
    /**
     *
     * @param {*} files
     */
    submitFiles(files) {
      this.$store.commit("setScopedFilesToUpload", files);
    },
    /**
     *
     */
    setBreadcrumbs() {
      // get parents from search scope
      const clonedSubfolders = _.cloneDeep(
        this.searchScope[0].meta.hierarchicalParents
      );

      const breadList = [];
      clonedSubfolders.forEach((folder) => {
        const breadcrumb = {
          title: folder.folder_name,
          disabled: false,

          to: {
            name: "Content",
            params: {
              folder_id: folder.folder_id,
            },
          },
          color: "primary",
          folder_id: folder.folder_id,
        };

        breadList.push(breadcrumb);
      });

      this.$store.commit("setBreadcrumbs", breadList);
    },
    /**
     *
     */
    calcHeight() {
      const fileUploadHeight = this.$refs.fileUpload
        ? this.$refs.fileUpload.clientHeight
        : 0;
      const height =
        window.innerHeight -
        // navbar
        64 -
        // p-4
        1.5 * 16 * 3 -
        // buttons
        70 -
        fileUploadHeight -
        180;
      return height;
    },
  },
  async created() {
    this.dashboardTabs[0].component.componentProps.height =
      this.calcHeight() - 60;
    this.dashboardTabs[1].component.componentProps.height = this.calcHeight();
    // register dynamic components
    app.component("ProgressBar");
    app.component("DocumentsTabComponent");
    app.component("SearchQueriesTabComponent");

    // init state variables
    //this.$store.commit("setSearchResults", null);
    if (this.folder_id) {
      this.$store.commit("setCurrentFolderId", this.folder_id);
    } else {
      this.$store.commit("resetBreadcrumb", null);
      const home = await this.home();
      this.initTree({ itemId: this.homeId, status: home.status });
    }

    // set breadcrumbs
    this.setBreadcrumbs();
  },
};
</script>

<style></style>
