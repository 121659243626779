<template>
  <div class="h-100">
    <splitpanes class="default-theme" :horizontal="isSplitViewHorizontal">
      <pane min-size="30" max-size="70" style="background-color: transparent">
        <documentViewer
          :color="'transparent'"
          class="h-100 column-padding"
          :fileName="fileName"
          :pdfBinary="pdfBinary"
          :pageToShow="pageToShowComputed"
          @resetPage="resetPage"
          :boilerplate="boilerplate"
        />
      </pane>
      <pane
        min-size="30"
        max-size="70"
        style="background-color: transparent"
        class="column-padding d-flex flex-column overflow-y-auto"
      >
        <searchInput
          :width="'none'"
          style="flex: none !important"
          :color="'input-primary'"
          :density="'comfortable'"
          :searchQuery="searchQuery"
          :searchScope="searchScope"
          :searchInProgress="searchInProgress"
          @doSearch="doSearch"
          @changeScope="changeSearchScope"
        ></searchInput>
        <searchResult
          class="flex-grow-1"
          :searchResults="searchResults"
          :searchQuery="searchQuery"
          :searchInProgress="searchInProgress"
          :show-llm-skeleton="showLlmSkeleton"
          :selected-index="selectedIndex"
          @resultClicked="showResultOnDocument"
          @resultRated="updateRating"
          @keySentencesLoaded="setKeySentencesLoaded"
          @restoreResult="restoreResult"
        />
      </pane>
    </splitpanes>
  </div>
</template>

<script>
import DocumentViewer from "../document/DocumentViewerComponent.vue";
import SearchInputComponent from "./SearchInputComponent.vue";
import SearchResults from "./SearchResultsComponent.vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  name: "InspectSearch",
  components: {
    documentViewer: DocumentViewer,
    searchResult: SearchResults,
    searchInput: SearchInputComponent,
    Splitpanes,
    Pane,
  },
  computed: {
    pageToShowComputed: {
      get: function () {
        if (this.searchResults) {
          return this.page;
        }
        return 1;
      },
      set: function (value) {
        this.page = value;
      },
    },
  },
  props: {
    fileName: {
      default: null,
      type: String,
    },
    searchQuery: {
      default: null,
      type: String,
    },
    pdfBinary: {
      type: Object,
      default() {
        return new Object();
      },
    },
    searchResults: {
      type: Object,
      default() {
        return null;
      },
    },
    boilerplate: {
      type: Boolean,
      default: false,
    },
    searchScope: {
      type: Array,
      default() {
        return [];
      },
    },
    searchInProgress: {
      type: Boolean,
      default: false,
    },
    showLlmSkeleton: {
      type: Boolean,
      default: false,
    },
    selectedIndex: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    searchResults: {
      handler(newValue) {
        if (newValue) {
          this.page = newValue.items.length > 0 ? newValue.items[0].page : 1;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      keySentencesLoaded: 10, // this is the same step as in the search result component
      page: 1,
      isSplitViewHorizontal: false,
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isSplitViewHorizontal = window.innerWidth < 960;
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    async showResultOnDocument(searchResultId) {
      // go to page
      this.$emit("highlightResult", {
        searchResultId: searchResultId,
        goToPage: this.goToPage,
      });
      // this.goToPage(absoluteIndex);
    },
    /**
     *
     * @param {*} keySentencesLoaded
     */
    async setKeySentencesLoaded(keySentencesLoaded) {
      // every time the results are expanded,
      // update progressively the highlighted text
      this.$emit("keySentencesLoadedInspect", keySentencesLoaded);
    },
    goToPage(searchResultId) {
      const result = this.searchResults.items.find(
        (element) => element.search_result_id === searchResultId
      );
      this.pageToShowComputed = result.page;
    },
    resetPage() {
      this.pageToShowComputed = 0;
    },
    updateRating({ rate, searchResultId }) {
      this.$emit("updateRating", { rate, searchResultId });
    },
    /**
     *
     */
    doSearch(searchQueryObject) {
      this.$store.commit("setSearchQueryObject", searchQueryObject);
      this.$emit("search");
    },
    /**
     *
     * @param {*} item
     */
    changeSearchScope(item) {
      this.$emit("changeScope", item);
    },
    /**
     *
     * @param {*} absoluteIndex
     */
    restoreResult(searchResultId) {
      this.$emit("restoreResult", searchResultId);
    },
  },
};
</script>
<style>
.column-padding {
  padding: 0 2%;
}
.inspect-style {
  justify-content: start;
  align-items: stretch;
}
</style>
