export class TreeViewItem {
  constructor(
    name,
    id,
    itemId,
    dbItemId,
    parentId,
    type,
    status,
    icon,
    children = [],
    selected = false,
    expanded = false,
    disabled = false,
    meta = {}
  ) {
    this.name = name;
    this.id = id;
    this.itemId = itemId;
    this.dbItemId = dbItemId;
    this.parentId = parentId;
    this.type = type;
    this.status = status;
    this.icon = icon;
    this.children = children;
    this.selected = selected;
    this.expanded = expanded;
    this.disabled = disabled;
    this.meta = meta;
  }
}

const getDefaultTreeState = () => {
  return { searchScope: [], tree: [], cache: {}, nodesInProgress: [] };
};

// initial state
const state = getDefaultTreeState();

const getters = {
  stateSearchScope: (state) => state.searchScope,
  stateTree: (state) => state.tree,
  stateCache: (state) => state.cache,
  stateNodesInProgress: (state) => state.nodesInProgress,
};

const actions = {
  initTree({ state, commit }, { itemId, status }) {
    if (state.tree.length == 0) {
      const treeNode = new TreeViewItem(
        "home",
        "1",
        itemId,
        itemId,
        null,
        "folder",
        status,
        "mdi-folder-outline",
        [],
        true,
        true,
        false,
        {
          hierarchicalParents: [
            {
              folder_id: itemId,
              folder_name: "home",
            },
          ],
        }
      );
      state.tree.push(treeNode);
      state.searchScope = [treeNode];
    } else {
      commit("setSearchScope", state.tree[0]);
    }
  },
};
const mutations = {
  /**
   *
   * @param {*} state
   * @param {*} itemId
   */

  /**
   *
   * @param {*} state
   * @param {*} searchScope
   */
  setSearchScope(state, newScope) {
    // from the
    newScope.selected = true;
    if (state.searchScope[0].id !== newScope.id) {
      state.searchScope[0].selected = false;
    }

    state.searchScope[0] = newScope;
  },
  /**
   *
   * @param {*} state
   * @param {*} breadcrumbs
   */
  // eslint-disable-next-line no-empty-pattern
  setChildNodes({}, { parentNode, nodes }) {
    parentNode.children = [];
    // create one node for each item in nodes
    let idCounter = 1;
    const generateId = () => idCounter++;
    nodes.forEach((node) => {
      const itemId =
        node.type === "document" ? node.document_id : node.folder_id;
      const children =
        node.type === "document" || node.status === 210
          ? []
          : [new TreeViewItem(" ", -1, -1, null, "folder")];
      const icon =
        node.type === "document" ? "mdi-file-outline" : "mdi-folder-outline";

      parentNode.children.push(
        new TreeViewItem(
          node.name,
          parentNode.id + "_" + generateId(),
          itemId,
          node.type == "document" ? node.document_metadata_id : node.folder_id,
          parentNode.itemId,
          node.type,
          node.status,
          icon,
          children,
          false,
          false,
          false,
          {
            hierarchicalParents:
              node.type == "document"
                ? parentNode.meta.hierarchicalParents
                : parentNode.meta.hierarchicalParents.concat({
                    folder_id: itemId,
                    folder_name: node.name,
                  }),
          }
        )
      );
    });
  },
  /**
   *
   * @param {*} state
   * @param {*} param1
   */
  setCache(state, { folderId, content }) {
    state.cache[folderId] = content;
  },
  setNodesInProgress(state, nodes) {
    state.nodesInProgress = nodes;
  },
  /**
   *
   * @param {*} state
   */
  resetTreeState(state) {
    Object.assign(state, getDefaultTreeState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
