<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div class="text-center" v-if="filesToUploadGui">
    <v-btn
      class="text-none p-0"
      stacked
      color="button-secondary"
      @click="showUploadStatusModal = !showUploadStatusModal"
    >
      <v-badge
        v-if="filesToUploadGui.length > 0"
        :content="uploadCount"
        :color="badgeColor"
      >
        <v-icon>mdi-upload</v-icon>
      </v-badge>
    </v-btn>
    <uploadStatusModal
      class="d-flex w-auto m-auto"
      v-model="showUploadStatusModal"
      :uploadTableConfig="uploadTableConfig"
      v-on:minimize="showUploadStatusModal = false"
      v-on:removeFile="removeFile"
      v-on:openDocument="openDocument"
    ></uploadStatusModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UploadStatusModal from "./UploadStatusModal.vue";
import app from "../../main.js";
import { getThemes } from "../../plugins/vuetify/paletteUtils";
import cacheService from "../../views/dashboard/services/dashboardService";

export default {
  name: "UploadStatusComponent",
  components: {
    uploadStatusModal: UploadStatusModal,
  },
  computed: {
    ...mapGetters({
      stateScopedFilesToUpload: "stateScopedFilesToUpload",
      searchScope: "stateSearchScope",
      currentFolderId: "stateCurrentFolderId",
      user: "stateUser",
      pdfs: "statePdfs",
      polling: "statePolling",
      progress: "stateProgress",
      tree: "stateTree",
      cache: "stateCache",
    }),

    uploadCount: {
      set(value) {
        this.fileCount = value;
      },
      get() {
        return this.fileCount;
      },
    },
    badgeColor: {
      set(value) {
        this.badgeClr = value;
      },
      get() {
        return this.badgeClr;
      },
    },
  },
  watch: {
    /**
     *
     * @param {*} newValue
     */
    async stateScopedFilesToUpload(newFilesToUpload) {
      this.currentBatch =
        this.currentBatch == null ? Date.now() : this.currentBatch;

      this.parents = this.getGrouppedFiles(newFilesToUpload);
      if (newFilesToUpload) {
        this.showUploadStatusModal = true;

        //first update the badge and the gui list
        this.filesToUploadGui = this.filesToUploadGui
          ? this.filesToUploadGui.concat(newFilesToUpload)
          : newFilesToUpload;
        this.fileCount = this.fileCount + newFilesToUpload.length;
        this.filesToUploadGui.forEach((file) => {
          file.inlineActions = [
            {
              icon: "mdi-open-in-new",
              action: "viewDocument",
              title: "legenda_197",
              showActionIcon: false,
              iconColor: "button-contrast",
            },
          ];
        });
        this.uploadTableConfig.content = this.filesToUploadGui;

        // this is to empty the list of the file component
        this.filesQueued = !this.filesQueued;
        this.$emit("filesQueued", this.filesQueued);

        await this.uploadStructure();
      }
    },
    /**
     *
     * @param {*} newValue
     */
    async polling(newValue) {
      if (newValue == "start") {
        const store = this.$store;
        const currentBatch = this.currentBatch;

        const getPdfsClone = this.getPdfsByBatchId;

        this.intervalId = window.setInterval(async function () {
          // get the files (and folders) every two seconds
          try {
            await getPdfsClone(currentBatch);
          } catch (error) {
            store.commit("setPolling", "stop");

            //close upload status modal
            this.showUploadStatusModal = false;
          }
        }, 2500);
      } else if (newValue === "stop" || !newValue) {
        // polling can stop, something went wrong or upload is done
        clearInterval(this.intervalId);
        this.cancelUpload = false;
        //close upload status modal
        this.showUploadStatusModal = false;
      }
    },
    /**
     *
     */
    progress(newValue) {
      // check that there are no inprogress anymore and stop polling
      if (this.filesToUploadGui) {
        //for everything that we have polled, update the status
        this.filesToUploadGui.map((fileToUpload) => {
          const progressFile = newValue.find(
            (file) => file.document_id == fileToUpload.documentId
          );
          if (progressFile) {
            this.updateFileInGui(fileToUpload, progressFile);
          }
        });
        this.updateGui();
      }
      // upload finished, stop polling
      else if (this.polling === "start") {
        this.$store.commit("setPolling", "stop");
      }
    },
  },

  data() {
    return {
      parents: [],
      colors: null,
      menu: false,
      filesQueued: false,
      badgeClr: "warning",
      fileCount: 0,
      intervalId: 0,
      filesToUploadGui: null,
      currentBatch: null,
      showUploadStatusModal: false,
      cancelUpload: false,
      uploadTableConfig: {
        autoSort: true,
        header: [
          {
            name: "legenda_112",
            descr: "filePath",
            width: "30%",
            hasInlineActions: true,
          },
          { name: "", descr: "chips" },
          {
            name: "legenda_078",
            descr: "completeSize",
          },
          {
            name: "legenda_068",
            descr: "uploadStatus",
            width: "15%",
            center: true,
            isDynamic: true,
            component: {
              name: "ProgressBarComponent",
              path: "components/upload",
            },
          },
          {
            name: "legenda_114",
            descr: "uploadedTime",
            sortBy: "uploadedTime",
            sort: "desc",
            type: "datetime",
            activeSort: false,
          },
          {
            name: "legenda_077",
            descr: "action",
            center: true,
          },
        ],
        content: [],
        actions: [
          {
            icon: "mdi-delete-outline",
            action: "removeFile",
            title: "legenda_172",
          },
        ],
      },
    };
  },
  methods: {
    /**
     *
     */
    ...mapActions(["storeFolder", "storePdf", "getPdfs", "getPdfsByBatchId"]),
    /**
     *
     */
    async uploadStructure() {
      this.badgeColor = "warning";

      await this.uploadFolders();

      await this.uploadFiles();
    },
    /**
     *
     */
    updateProgressBar(file, progressFile) {
      file.status = progressFile.status;

      switch (true) {
        case file.status === 0: {
          file.componentProps = {
            uploadProgressProp: 0,
            color: this.colors["status-empty"],
          };
          break;
        }
        case file.status === 110: {
          file.componentProps = {
            uploadProgressProp: 10,
            color: this.colors["status-empty"],
          };
          break;
        }
        case file.status === 120: {
          file.componentProps = {
            uploadProgressProp: 15,
            color: this.colors["status-pdf-conversion"],
          };
          break;
        }
        case file.status === 130: {
          file.componentProps = {
            uploadProgressProp: 20,
            color: this.colors["status-in-progress"],
          };
          break;
        }
        case file.status === 140: {
          file.componentProps = {
            uploadProgressProp: 25,
            color: this.colors["status-in-progress-ocr"],
          };
          break;
        }
        case file.status === 150: {
          file.componentProps = {
            uploadProgressProp: 30,
            color: this.colors["status-in-progress"],
          };
          break;
        }
        case file.status === 160: {
          file.componentProps = {
            uploadProgressProp: 40,
            color: this.colors["status-in-progress"],
          };
          break;
        }
        case file.status === 200: {
          file.componentProps = {
            uploadProgressProp: 100,
            color: this.colors["status-searchable"],
          };
          break;
        }
        case file.status === 300: {
          const oldProgress = file.componentProps.uploadProgressProp;
          file.componentProps = {
            uploadProgressProp: oldProgress,
            color: this.colors["status-failed"],
            text: "legenda_043",
          };
          break;
        }
        case file.status <= 40: {
          const percentage =
            40 + file.status * 1.5 > 100 ? 100 : 40 + file.status * 1.5;
          file.componentProps = {
            uploadProgressProp: percentage,
            color:
              percentage === 100
                ? this.colors["status-searchable"]
                : this.colors["status-in-progress"],
          };
          break;
        }
      }
    },
    /**
     *
     */
    updateFileInGui(fileToUpload, progressFile) {
      this.updateProgressBar(fileToUpload, progressFile);

      fileToUpload.documentId = progressFile.document_id;
      fileToUpload.documentMetadataId = progressFile.document_metadata_id;
      fileToUpload.uploadedTime = progressFile.created;
      fileToUpload.actions[0].actionEnabled = fileToUpload.status;
      fileToUpload.inlineActions[0].showActionIcon = ![0, 300].includes(
        progressFile.status
      );
      if (progressFile.status === 140) {
        const chip = {
          name: "OCR",
          text: "legenda_129",
          location: "bottom",
          icon: "mdi-head-dots-horizontal-outline",
          color: "status-in-progress-ocr",
          class: "ml-2",
        };
        fileToUpload.chips = [chip];
      }
    },
    /**
     *
     */
    updateStatusBadge() {
      // check on the  list
      if (this.filesToUploadGui) {
        const completedFiles = this.filesToUploadGui.filter(
          (file) => file.status == 200
        );
        const failedFiles = this.filesToUploadGui.filter(
          (file) => file.status == 300
        );
        this.uploadCount = this.filesToUploadGui.length - completedFiles.length;
        if (completedFiles.length == this.filesToUploadGui.length) {
          this.badgeColor = "success";
          this.uploadCount = 0;
        }
        if (failedFiles.length > 0) {
          this.badgeColor = "error";
          this.uploadCount = failedFiles.length;
        }
      }
    },
    /**
     *
     * @param {*} currentFolderId
     * @param {*} folderName
     * @param {*} resolve
     * @param {*} reject
     */
    async storeSingleFolder(currentFolderId, folderPath) {
      try {
        // todo parametrize permissions and folder type
        const result = await this.storeFolder({
          parentFolderId: currentFolderId,
          folderName: folderPath,
          permissions: "write",
          folderType: "private",
          folderStatus: 210,
        });
        return result.data.message;
      } catch (error) {
        this.filesToUploadGui.map((file) => {
          if (file.parentPath == folderPath) {
            file.status = 300;
          }
        });
      }
    },
    /**
     *
     */
    getGrouppedFiles(parents) {
      const groups = parents.reduce((res, item) => {
        // find in res if there's an object with groupName === item.key1
        const idx = res
          ? res.findIndex((el) => el.groupName === item["parentPath"])
          : -1;
        if (idx >= 0) {
          // push item to object.elements
          res[idx].elements.push(item);
        } else {
          // else add object with groupName === item.key1
          const obj = { groupName: item.parentPath, elements: [item] };

          if (res) {
            res.push(obj);
          }
        }

        return res;
      }, []);
      return groups;
    },
    /**
     *
     */
    async uploadFolders() {
      //map the files to their common parent

      //this.filesToUploadWithParentId = this.filesToUploadGui.map((a) => ({ ...a }));
      //iterate through these common parents
      let folderId = this.currentFolderId;
      for (let i = 0; i < this.parents.length; i++) {
        //let searchScopeRoot = this.tree[0];
        if (this.cancelUpload) break;
        if (this.parents[i].groupName != "") {
          //for each common parent structure, sort it in the db
          folderId = await this.storeSingleFolder(
            this.currentFolderId,
            this.parents[i].groupName
          );
        }
        this.filesToUploadGui.forEach((file) => {
          if (
            file.parentPath === this.parents[i].groupName &&
            file.scope.itemId === this.currentFolderId
          )
            file.folderId = folderId;
        });
      }
    },

    /**
     * Start polling time and file upload
     */
    async uploadFiles() {
      //start polling timer
      if (this.polling !== "start") {
        this.$store.commit("setPolling", "start");
      }
      // forEach works in async ways, even declaring as async does not respect await
      //therefore, use a for loop
      for (let i = 0; i < this.filesToUploadGui.length; i++) {
        if (this.cancelUpload) break;
        // if status is not defined, set it to 0
        if (!this.filesToUploadGui[i].status) {
          this.filesToUploadGui[i].actions[0].actionEnabled = false;

          this.filesToUploadGui[i].componentProps = {
            uploadProgressProp: 0,
            color: this.colors["status-empty"],
            text: "legenda_044",
          };
          await this.uploadFile(this.filesToUploadGui[i]);
        }
      }
    },

    /**
     *
     * @param {*} file
     */
    async uploadFile(file) {
      try {
        file.documentId = await this.storePdf({
          folder_id: file.folderId,
          file: file,
          //todo parametrize permissions
          permissions: "write",
        });
      } catch (error) {
        // upload failed
        file.status = 300;
        file.componentProps = {
          text: "legenda_043",
          color: this.colors["status-empty"],
        };
        file.actions[0].actionEnabled = true;
        this.updateStatusBadge();
        return;
      }
    },
    /**
     *
     */
    updateGui() {
      //update progress badge
      this.updateStatusBadge();
      //and check what to do with the other queues
      this.checkPendingUploads();
    },
    /**
     *
     */
    checkPendingUploads() {
      // if we have anything pending, dont stop polling , upload is in progress
      const filesPending = [...this.filesToUploadGui].filter((element) => {
        return element.status === 0;
      });
      if (filesPending.length > 0) return;

      const filesInProgress = [...this.filesToUploadGui].filter((element) => {
        return ![200, 210, 300].includes(element.status);
      });
      // check if we need to stop polling: if no file is in progress anymore, stop polling
      if (filesInProgress.length == 0) {
        // no other elements in progress, so stop polling
        this.$store.commit("setPolling", "stop");
      }
    },

    preventReload(event) {
      // show alert if there is at least one element with status 0 (not yet uploaded)
      if (
        this.filesToUploadGui &&
        this.filesToUploadGui.find((file) => file.status === 0)
      ) {
        event.preventDefault();
        return (event.returnValue = "");
      } else {
        return event.returnValue;
      }
    },
    /**
     *
     */
    removeFile(fileName) {
      // Find the index of the file
      const index = this.filesToUploadGui.findIndex(
        (file) => file.filename === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) {
        this.filesToUploadGui.splice(index, 1);
        this.uploadTableConfig.content = this.filesToUploadGui;
        this.fileCount = this.fileCount - 1;
      }
      this.showUploadStatusModal = this.filesToUploadGui.length > 0;
    },
    /**
     *
     * @param {*} file
     */
    async openDocument(file) {
      //this is where the file is uploaded, so if it is not in the tree yet, create the stucture
      let newScope = this.$filters.deepSearch(
        this.tree,
        file.documentId,
        "itemId",
        "children" //where to look for
      );

      if (!newScope) {
        // narrow down the tree iterations
        let fileSearchScope = this.$filters.deepSearch(
          this.tree,
          file.scope.itemId,
          "itemId",
          "children" //where to look for
        );

        newScope = await this.addNodeRecursively(
          fileSearchScope, //the scope of the upload
          file.documentId,
          file.folderId //the parent folder
        );
      } else {
        //make sure status is updated
        newScope.status = this.filesToUploadGui.find(
          (el) => el.documentId === newScope.itemId
        ).status;
      }

      this.$store.commit("setSearchScope", newScope);
      this.showUploadStatusModal = false;

      this.$router.push({
        name: "DocumentExplorer",
        params: {
          folder_id: file.folderId,
          doc_metadata_id: file.documentMetadataId,
        },
        state: {
          name: file.filename,
          document_id: file.documentId,
        },
      });
    },
    /**
     *
     * @param {*} node
     * @param {*} targetId
     * @param {*} targetFolderId
     */
    async addNodeRecursively(node, targetId, targetFolderId) {
      const children = await cacheService.setCache(node.itemId);
      // set the tree nodes of the parent
      this.$store.commit("setChildNodes", {
        parentNode: node,
        nodes: children,
      });
      node.expanded = true;
      const childFolders = node.children.filter(
        (element) => element.type == "folder"
      );
      const childDocuments = node.children.filter(
        (element) => element.type == "document"
      );

      //first search the documents
      const documentFound = childDocuments.find(
        (doc) => doc.itemId == targetId
      );
      if (documentFound) {
        node.expanded = true;
        const nodeToReturn = node.children.find((el) => el.itemId === targetId);

        return nodeToReturn;
      }

      //not in the documents, search in folders
      if (childFolders && childFolders.length > 0) {
        for (const child of childFolders) {
          if (child.itemId === targetFolderId) {
            node.expanded = true;
            const subChildren = await cacheService.setCache(child.itemId);
            // set the tree nodes of the parent
            this.$store.commit("setChildNodes", {
              parentNode: child,
              nodes: subChildren,
            });
            return child.children.find((el) => el.itemId === targetId);
          } else {
            child.expanded = true;
            const found = await this.addNodeRecursively(
              child,
              targetId,
              targetFolderId
            );
            if (found) {
              // Ensure the parent is expanded
              child.expanded = true;
              return found;
            }
          }
        }
      }
      return false;
    },
  },
  /**
   *
   */
  created() {
    //register the component to be dynamically loaded, otherwise webpack wont find it
    app.component("ProgressBar");
    this.$store.commit("setPolling", "stop");
    this.colors = getThemes().legendaTheme.colors;
  },

  async beforeMount() {
    window.addEventListener(
      "beforeunload",
      (event) => {
        this.preventReload(event);
      },
      { capture: true }
    );
  },
  async beforeUnmount() {
    window.removeEventListener(
      "beforeunload",
      (event) => {
        this.preventReload(event);
      },
      { capture: true }
    );
  },
};
</script>
