<template>
  <div>
    <v-app-bar color="toolbar-primary" style="box-shadow: none">
      <router-link to="/">
        <v-img
          class="my-auto"
          :src="logoImg"
          :min-width="100"
          style="width: 10vw; height: auto"
        />
      </router-link>

      <v-spacer></v-spacer>

      <v-btn
        title="Metis"
        color="button-secondary"
        class="text-none p-0"
        plain
        stacked
        :href="metisLink"
        target="_blank"
      >
        Metis
      </v-btn>

      <language></language>

      <div v-show="this.$vuetify.display.smAndUp">
        <authButtons />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import logoImgBlack from "../../assets/images/legenda-logo.svg";
import LanguageComponent from "../ui/language/LanguageComponent.vue";
import AuthButtonsComponent from "../ui/AuthButtonsComponent.vue";

export default {
  name: "GeneralNavBarComponent",
  components: {
    language: LanguageComponent,
    authButtons: AuthButtonsComponent,
  },
  computed: {
    /**
     *
     */
    metisLink() {
      return this.$root.$i18n.locale.localeCompare("it") == 0
        ? "https://metisai.it"
        : "https://metisai.it/en";
    },
  },
  methods: {
    updateLanguage(language) {
      this.currentLanguage = language;
    },
  },
  data() {
    return {
      menu: false,
      logoImg: logoImgBlack,
      currentLanguage: this.$root.$i18n.locale.toUpperCase(),
    };
  },
};
</script>

<style></style>
