<template>
  <div class="intro-wrapper">
    <div class="intro-info">
      <div class="text-h23 p-1 m-1 font-weight-bold">
        {{ $t("legenda_161") }}
      </div>
      <div class="text-h6 p-1 m-1">{{ $t("legenda_162") }}</div>
    </div>
    <v-slide-y-transition :mode="'in-out'" :hideOnLeave="true" appear>
      <authButtons class="auth-button-body"></authButtons>
    </v-slide-y-transition>

    <div class="intro-features">
      <div v-for="feature in features" :key="feature" class="m-1 p-1">
        <v-icon color="icon-secondary">mdi-check</v-icon>
        <span>{{ $t(feature) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AuthButtonsComponent from "../../../../components/ui/AuthButtonsComponent.vue";

export default {
  name: "IntroComponent",
  components: {
    authButtons: AuthButtonsComponent,
  },
  computed: {},
  data() {
    return {
      features: ["legenda_163", "legenda_164", "legenda_165"],
    };
  },
};
</script>

<style>
.intro-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 700px;
}

.intro-features {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0px 15px !important;
}

@media (max-width: 808px) {
  .intro-features {
    flex-direction: column;
  }
}
</style>
