<template>
  <div class="quotes-wrapper">
    <div class="quotes-intro">
      <div class="text-h34 p-2 m-2 font-weight-bold">
        {{ $t("legenda_184") }}
      </div>
    </div>
    <div style="padding: 4rem 1rem">
      <v-row class="justify-content-center">
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="4"
          v-for="quote in quotes"
          :key="quote.person"
        >
          <v-card
            variant="outlined"
            class="rounded-xl border-md border-opacity-50 quote-layout quote-z-index"
          >
            <template v-slot:text>
              <div
                class="font-weight-normal p-3 quote-text"
                style="font-size: large"
              >
                "{{ $t(quote.comment) }}"
              </div>
            </template>

            <v-card-actions class="quote-signature">
              <v-list-item class="w-100">
                <template v-slot:prepend>
                  <v-avatar :image="quote.icon"></v-avatar>
                </template>

                <v-list-item-title
                  class="font-weight-bold"
                  style="font-size: larger; text-wrap: wrap"
                  >{{ quote.person }}</v-list-item-title
                >

                <v-list-item-subtitle>{{
                  $t(quote.role)
                }}</v-list-item-subtitle>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="bg-image-wrapper">
      <div class="bg-image">
        <div class="bg-image-container">
          <v-img
            :src="require('../../../../assets/logo/logo_yellow_white.png')"
            alt=""
          ></v-img>
        </div>
      </div>
    </div>
    <authButtons class="auth-button-body quote-z-index"></authButtons>
    <signature class="quote-z-index"></signature>
  </div>
</template>
<script>
import logoYellow from "../../../../assets/logo/logo_yellow.png";
import logoGreen from "../../../../assets/logo/logo_green.png";
import logoWhite from "../../../../assets/logo/logo_white.png";
import AuthButtonsComponent from "../../../../components/ui/AuthButtonsComponent.vue";
import SignatureComponent from "../../../../components/ui/SignatureComponent.vue";

export default {
  name: "QuotesComponent",
  components: {
    authButtons: AuthButtonsComponent,
    signature: SignatureComponent,
  },
  data() {
    return {
      quotes: [
        {
          comment: "legenda_185",
          icon: logoWhite,
          person: "Avv. Fabio Iudica",
          role: "legenda_186",
        },
        {
          comment: "legenda_187",
          icon: logoGreen,
          person: "Riccardo D'Alessandri",
          role: "legenda_188",
        },
        {
          comment: "legenda_189",
          icon: logoYellow,
          person: "Florencia Marotta-Wurgler",
          role: "legenda_190",
        },
      ],
    };
  },
};
</script>
<style>
.quotes-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 2rem 4rem;
  width: 100%;
}
.quotes-intro {
  max-width: 530px;
  text-align: center;
  text-wrap: wrap;
}

.quote-layout {
  padding: 15px 0px !important;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between !important;
  background-color: white !important;
}

.quote-signature > div {
  display: flex !important;
  justify-content: left;
}

.bg-image-wrapper {
  flex: none;
  height: 493px;
  overflow: visible;
  position: absolute;
  right: 1px;
  width: 494px;
  z-index: -1;
}
.bg-image {
  image-rendering: pixelated;
  flex-shrink: 0;
  fill: rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  flex: none;
  inset: 0;
  position: absolute;
}
.bg-image-container {
  width: 100%;
  height: 100%;
  aspect-ratio: inherit;
}
.quote-z-index {
  z-index: 2 !important;
}

@media (max-width: 700px) {
  .quotes-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 5rem 1rem;
    width: 100%;
  }
  .quote-signature > div {
    font-size: medium;
  }
  .quote-layout {
    font-size: small !important;
  }
}
</style>
