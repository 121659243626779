function deepSearch(data, value, key = "folder_id", sub = "children") {
  if (!data) return false;

  for (const node of data) {
    if (node[key] === value) {
      return node;
    }
    const result = deepSearch(node[sub], value, key, sub);
    if (result) {
      return result;
    }
  }

  return false;
}

/**
 * Sorts the folder content (folders and documents)
 * @param {*} pdfList
 * @param {*} folderList
 */
function sortFolderContent(pdfList, folderList) {
  let contentToSort = null;

  const pdfs = pdfList ?? [];
  pdfs.forEach((pdf) => (pdf.type = "document"));

  const subFolders = folderList ?? [];
  subFolders.forEach((folder) => {
    folder.type = "folder";
    folder.name = folder.folder_name;
  });

  contentToSort = pdfs.concat(subFolders);
  sortTable(contentToSort);
  return contentToSort;
}

/**
 *
 * @param {*} content
 */
function sortTable(content) {
  if (content) {
    content.sort(function (a, b) {
      return (
        b.type.localeCompare(a.type) ||
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );
    });
  }
}

export { deepSearch, sortFolderContent };
